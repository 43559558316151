import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  BAVBenefitDetailsDTO,
  BenefitDTOBenefitEnum,
  CouponBenefitDetailsDTO,
  CouponsV2BenefitDetailsDTO,
  CustomBenefitDetailsDTO,
  FlexBenefitDetailsDTO,
  GiftsBenefitDetailsDTO,
} from 'probonio-shared-ui/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalBox } from '../../component/layout/BoxLayout';
import { GiftButton } from '../coupons/gift/GiftButton';
import { GiftV2Button } from '../couponsV2/gift/GiftV2Button';
import { BenefitItem } from './BenefitItem';
import { useActiveBenefitsQuery } from './useActiveBenefitsQuery';

export const MyBenefitList: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const getActiveEmployment = useActiveEmployment();
  const { bavNotInterested } = getActiveEmployment();

  const { data: activeBenefits, isLoading } = useActiveBenefitsQuery();

  const benefits = useMemo(() => {
    if (!activeBenefits) {
      return [];
    }

    const benefitElements: React.ReactNode[] = [];
    const flexBenefits =
      (activeBenefits.benefits.find(dto => dto.benefit === BenefitDTOBenefitEnum.Flex)?.details as FlexBenefitDetailsDTO)?.flexBenefits ||
      [];

    // Filter and order the benefits as configured above.
    activeBenefits.benefits.forEach(activeBenefit => {
      if (
        !Object.values(BenefitDTOBenefitEnum).includes(activeBenefit.benefit) ||
        flexBenefits.includes(activeBenefit.benefit) ||
        activeBenefit.benefit === BenefitDTOBenefitEnum.Fitness
      ) {
        return;
      }

      // As custom benefits can exist several times, include also the customBenefitId in the key.
      let key = activeBenefit.benefit;
      if (activeBenefit.benefit === BenefitDTOBenefitEnum.Custom) {
        const detailCustom = activeBenefit.details as CustomBenefitDetailsDTO;
        if (!detailCustom.showTile) {
          return;
        }

        key += detailCustom.customBenefitId;
      }

      if (
        activeBenefit.benefit === BenefitDTOBenefitEnum.Coupons &&
        !(activeBenefit.details as CouponBenefitDetailsDTO).currentlyActivated &&
        activeBenefits.benefits.some(benefit => benefit.benefit === BenefitDTOBenefitEnum.CouponsV2)
      ) {
        // hide inactive coupons V1 tile if coupons V2 tile is shown
        return;
      }
      if (
        activeBenefit.benefit === BenefitDTOBenefitEnum.CouponsV2 &&
        activeBenefits.benefits.some(
          benefit => benefit.benefit === BenefitDTOBenefitEnum.Coupons && (benefit.details as CouponBenefitDetailsDTO).currentlyActivated,
        )
      ) {
        // hide coupons V2 tile if active coupons V1 tile is shown
        return;
      }
      if (activeBenefit.benefit === BenefitDTOBenefitEnum.Bav) {
        const bavDetails = activeBenefit.details as BAVBenefitDetailsDTO;
        if (!bavDetails.activeContract && bavNotInterested) {
          return;
        }
      }

      if (activeBenefit.benefit !== BenefitDTOBenefitEnum.Gifts) {
        const hasAlternate =
          activeBenefit.benefit === BenefitDTOBenefitEnum.Coupons &&
          !!(activeBenefit.details as CouponBenefitDetailsDTO)?.alternateBenefits?.length;

        benefitElements.push(
          <Grid key={key} size={'grow'} maxHeight={activeBenefit.benefit === BenefitDTOBenefitEnum.Flex || hasAlternate ? 600 : 300}>
            <BenefitItem activeBenefit={activeBenefit} allBenefits={activeBenefits.benefits} />
          </Grid>,
        );
      }
    });

    return benefitElements;
  }, [activeBenefits, bavNotInterested]);

  const gifts = useMemo(() => {
    const allGifts = [];
    const v2GiftDetails = activeBenefits?.benefits?.find(b => b.benefit === BenefitDTOBenefitEnum.CouponsV2)
      ?.details as CouponsV2BenefitDetailsDTO;

    const giftDetails = activeBenefits?.benefits?.find(b => b.benefit === BenefitDTOBenefitEnum.Gifts)?.details as GiftsBenefitDetailsDTO;

    // if existent take first gift available

    if (v2GiftDetails?.availableGifts?.length) {
      const currentV2Gift = v2GiftDetails.availableGifts[0];
      allGifts.push(<GiftV2Button key={currentV2Gift.couponGiftId} gift={currentV2Gift} />);
    }
    if (giftDetails?.gifts?.length) {
      const currentGift = giftDetails.gifts[0];
      allGifts.push(<GiftButton key={currentGift.couponGiftId} gift={currentGift} />);
    }
    return allGifts;
  }, [activeBenefits]);

  return (
    <VerticalBox flexGrow={1}>
      {activeBenefits && activeBenefits.benefits.length > 0 ? (
        <Grid container rowSpacing={1} flexGrow={1} flexDirection="column" flexWrap="nowrap">
          {gifts}
          {benefits}
        </Grid>
      ) : (
        <VerticalBox justifyContent="center" flexGrow={1}>
          {!isLoading && (
            <Typography textAlign="center" variant={'h6'}>
              {t('noBenefitMessage')}
            </Typography>
          )}
        </VerticalBox>
      )}
    </VerticalBox>
  );
};
