/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCouponDefinitionListDTO } from '../dto';
// @ts-ignore
import { CouponBudgetOrderWithTenantListDTO } from '../dto';
// @ts-ignore
import { CouponBudgetOrdersDTO } from '../dto';
// @ts-ignore
import { CouponDefinitionDTO } from '../dto';
// @ts-ignore
import { CouponDefinitionListDTO } from '../dto';
// @ts-ignore
import { CouponGiftDTO } from '../dto';
// @ts-ignore
import { CouponGiftListDTO } from '../dto';
// @ts-ignore
import { CouponGiftOccasionListDTO } from '../dto';
// @ts-ignore
import { CouponGiftWithEmployeeListDTO } from '../dto';
// @ts-ignore
import { CouponOrderDTO } from '../dto';
// @ts-ignore
import { CouponOrderListDTO } from '../dto';
// @ts-ignore
import { CouponOrderWithEmployeeListDTO } from '../dto';
// @ts-ignore
import { CouponOrdersStatisticDTO } from '../dto';
// @ts-ignore
import { CouponTransactionsHistoryDTO } from '../dto';
// @ts-ignore
import { CreateCouponBudgetOrderDTO } from '../dto';
// @ts-ignore
import { CreateCouponGiftDTO } from '../dto';
// @ts-ignore
import { CreateCouponOrderDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { SaveGiftOccasionDTO } from '../dto';
// @ts-ignore
import { UpdateCouponBudgetOrderDTO } from '../dto';
// @ts-ignore
import { UpdateCouponDefinitionDTO } from '../dto';
// @ts-ignore
import { UpdateCouponGiftDTO } from '../dto';
// @ts-ignore
import { UpdateCouponOrderDTO } from '../dto';
// @ts-ignore
import { UpdateEmployeeCouponDefaultDTO } from '../dto';
/**
 * CouponsApi - axios parameter creator
 * @export
 */
export const CouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancels a pending coupon order
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCouponOrder: async (tenantId: string, employeeId: string, couponOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('cancelCouponOrder', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('cancelCouponOrder', 'employeeId', employeeId)
            // verify required parameter 'couponOrderId' is not null or undefined
            assertParamExists('cancelCouponOrder', 'couponOrderId', couponOrderId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/orders/{couponOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponOrderId"}}`, encodeURIComponent(String(couponOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary places a coupon budget order
         * @param {string} tenantId 
         * @param {CreateCouponBudgetOrderDTO} createCouponBudgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBudgetOrder: async (tenantId: string, createCouponBudgetOrderDTO: CreateCouponBudgetOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createBudgetOrder', 'tenantId', tenantId)
            // verify required parameter 'createCouponBudgetOrderDTO' is not null or undefined
            assertParamExists('createBudgetOrder', 'createCouponBudgetOrderDTO', createCouponBudgetOrderDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/budgetOrders`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCouponBudgetOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponGiftDTO} createCouponGiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponGift: async (tenantId: string, employeeId: string, createCouponGiftDTO: CreateCouponGiftDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createCouponGift', 'employeeId', employeeId)
            // verify required parameter 'createCouponGiftDTO' is not null or undefined
            assertParamExists('createCouponGift', 'createCouponGiftDTO', createCouponGiftDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/gifts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCouponGiftDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a coupon order for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponOrderDTO} createCouponOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOrder: async (tenantId: string, employeeId: string, createCouponOrderDTO: CreateCouponOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createCouponOrder', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createCouponOrder', 'employeeId', employeeId)
            // verify required parameter 'createCouponOrderDTO' is not null or undefined
            assertParamExists('createCouponOrder', 'createCouponOrderDTO', createCouponOrderDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/orders`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCouponOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an override for the next possible date
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOverride: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createCouponOverride', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createCouponOverride', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/override`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCouponGift: async (tenantId: string, employeeId: string, couponGiftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponGiftId' is not null or undefined
            assertParamExists('deleteCouponGift', 'couponGiftId', couponGiftId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/gifts/{couponGiftId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponGiftId"}}`, encodeURIComponent(String(couponGiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the end date to the current coupon override
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} overrideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endOverride: async (tenantId: string, employeeId: string, overrideId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('endOverride', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('endOverride', 'employeeId', employeeId)
            // verify required parameter 'overrideId' is not null or undefined
            assertParamExists('endOverride', 'overrideId', overrideId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/override/{overrideId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"overrideId"}}`, encodeURIComponent(String(overrideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the whole transaction history list in CSV format
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {boolean} [includeCouponOrders] 
         * @param {boolean} [includeGiftOrders] 
         * @param {boolean} [includeBudgetOrders] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTransactionHistory: async (tenantId: string, month?: string, includeCouponOrders?: boolean, includeGiftOrders?: boolean, includeBudgetOrders?: boolean, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('exportTransactionHistory', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/transactionHistory/export`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (includeCouponOrders !== undefined) {
                localVarQueryParameter['includeCouponOrders'] = includeCouponOrders;
            }

            if (includeGiftOrders !== undefined) {
                localVarQueryParameter['includeGiftOrders'] = includeGiftOrders;
            }

            if (includeBudgetOrders !== undefined) {
                localVarQueryParameter['includeBudgetOrders'] = includeBudgetOrders;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (withAggregations !== undefined) {
                localVarQueryParameter['withAggregations'] = withAggregations;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all coupons in the system
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCouponDefinitions: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/coupons/definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all coupons that a user can explore
         * @param {string} tenantId 
         * @param {boolean} [includeVariations] 
         * @param {number} [variation] 
         * @param {Array<number>} [variations] 
         * @param {string} [category] 
         * @param {boolean} [includeCombinations] 
         * @param {boolean} [isFallback] 
         * @param {boolean} [isFlexFallback] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCouponDefinitions: async (tenantId: string, includeVariations?: boolean, variation?: number, variations?: Array<number>, category?: string, includeCombinations?: boolean, isFallback?: boolean, isFlexFallback?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('findCouponDefinitions', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/definition`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (includeVariations !== undefined) {
                localVarQueryParameter['includeVariations'] = includeVariations;
            }

            if (variation !== undefined) {
                localVarQueryParameter['variation'] = variation;
            }

            if (variations) {
                localVarQueryParameter['variations'] = variations;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (includeCombinations !== undefined) {
                localVarQueryParameter['includeCombinations'] = includeCombinations;
            }

            if (isFallback !== undefined) {
                localVarQueryParameter['isFallback'] = isFallback;
            }

            if (isFlexFallback !== undefined) {
                localVarQueryParameter['isFlexFallback'] = isFlexFallback;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all budget orders for all tenants
         * @param {'ORDERED' | 'COMPLETED' | 'CANCELLED'} [budgetOrderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBudgetOrders: async (budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED', page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/coupons/budgetOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (budgetOrderStatus !== undefined) {
                localVarQueryParameter['budgetOrderStatus'] = budgetOrderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all budget orders
         * @param {string} tenantId 
         * @param {'ORDERED' | 'COMPLETED' | 'CANCELLED'} [budgetOrderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetOrders: async (tenantId: string, budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED', page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBudgetOrders', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/budgetOrders`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (budgetOrderStatus !== undefined) {
                localVarQueryParameter['budgetOrderStatus'] = budgetOrderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single coupon definition
         * @param {string} tenantId 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponDefinition: async (tenantId: string, couponId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCouponDefinition', 'tenantId', tenantId)
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('getCouponDefinition', 'couponId', couponId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/definition/{couponId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single coupon gift by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponGift: async (tenantId: string, employeeId: string, couponGiftId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponGiftId' is not null or undefined
            assertParamExists('getCouponGift', 'couponGiftId', couponGiftId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/gifts/{couponGiftId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponGiftId"}}`, encodeURIComponent(String(couponGiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a single coupon order by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponOrder: async (tenantId: string, employeeId: string, couponOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCouponOrder', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCouponOrder', 'employeeId', employeeId)
            // verify required parameter 'couponOrderId' is not null or undefined
            assertParamExists('getCouponOrder', 'couponOrderId', couponOrderId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/orders/{couponOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponOrderId"}}`, encodeURIComponent(String(couponOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupon orders for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponOrders: async (tenantId: string, employeeId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCouponOrders', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCouponOrders', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/orders`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns the statistic for coupons of one tenant for one month
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponOrdersStatistic: async (tenantId: string, month?: string, status?: 'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCouponOrdersStatistic', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/orders/statistic`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all custom gift occasions for the tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftOccasions: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getGiftOccasions', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/giftOccasions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary this will return the whole transaction history list
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {boolean} [includeCouponOrders] 
         * @param {boolean} [includeGiftOrders] 
         * @param {boolean} [includeBudgetOrders] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionHistory: async (tenantId: string, month?: string, includeCouponOrders?: boolean, includeGiftOrders?: boolean, includeBudgetOrders?: boolean, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTransactionHistory', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/transactionHistory`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (includeCouponOrders !== undefined) {
                localVarQueryParameter['includeCouponOrders'] = includeCouponOrders;
            }

            if (includeGiftOrders !== undefined) {
                localVarQueryParameter['includeGiftOrders'] = includeGiftOrders;
            }

            if (includeBudgetOrders !== undefined) {
                localVarQueryParameter['includeBudgetOrders'] = includeBudgetOrders;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (withAggregations !== undefined) {
                localVarQueryParameter['withAggregations'] = withAggregations;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all orders for all tenants
         * @param {Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [isAutomatedOrder] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrders: async (status?: Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>, filter?: string, isAutomatedOrder?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/coupons/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (isAutomatedOrder !== undefined) {
                localVarQueryParameter['isAutomatedOrder'] = isAutomatedOrder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupon gifts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCouponGifts: async (tenantId: string, employeeId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listCouponGifts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('listCouponGifts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/gifts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns coupon gifts for tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [isDelivered] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantCouponGifts: async (tenantId: string, filter?: string, isDelivered?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTenantCouponGifts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/gifts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (isDelivered !== undefined) {
                localVarQueryParameter['isDelivered'] = isDelivered;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a gift occasion for the tenant
         * @param {string} tenantId 
         * @param {'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER'} occasion 
         * @param {SaveGiftOccasionDTO} saveGiftOccasionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGiftOccasion: async (tenantId: string, occasion: 'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER', saveGiftOccasionDTO: SaveGiftOccasionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('saveGiftOccasion', 'tenantId', tenantId)
            // verify required parameter 'occasion' is not null or undefined
            assertParamExists('saveGiftOccasion', 'occasion', occasion)
            // verify required parameter 'saveGiftOccasionDTO' is not null or undefined
            assertParamExists('saveGiftOccasion', 'saveGiftOccasionDTO', saveGiftOccasionDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/giftOccasions/{occasion}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"occasion"}}`, encodeURIComponent(String(occasion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveGiftOccasionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sets coupon code to seen
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCouponCodeToSeen: async (tenantId: string, employeeId: string, couponOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('setCouponCodeToSeen', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('setCouponCodeToSeen', 'employeeId', employeeId)
            // verify required parameter 'couponOrderId' is not null or undefined
            assertParamExists('setCouponCodeToSeen', 'couponOrderId', couponOrderId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/orders/orderView/{couponOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponOrderId"}}`, encodeURIComponent(String(couponOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a coupon budget order
         * @param {string} tenantId 
         * @param {string} couponBudgetOrderId 
         * @param {UpdateCouponBudgetOrderDTO} updateCouponBudgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBudgetOrder: async (tenantId: string, couponBudgetOrderId: string, updateCouponBudgetOrderDTO: UpdateCouponBudgetOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateBudgetOrder', 'tenantId', tenantId)
            // verify required parameter 'couponBudgetOrderId' is not null or undefined
            assertParamExists('updateBudgetOrder', 'couponBudgetOrderId', couponBudgetOrderId)
            // verify required parameter 'updateCouponBudgetOrderDTO' is not null or undefined
            assertParamExists('updateBudgetOrder', 'updateCouponBudgetOrderDTO', updateCouponBudgetOrderDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/coupons/budgetOrders/{couponBudgetOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"couponBudgetOrderId"}}`, encodeURIComponent(String(couponBudgetOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCouponBudgetOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a coupon definition
         * @param {string} couponDefinitionId 
         * @param {UpdateCouponDefinitionDTO} updateCouponDefinitionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponDefinition: async (couponDefinitionId: string, updateCouponDefinitionDTO: UpdateCouponDefinitionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'couponDefinitionId' is not null or undefined
            assertParamExists('updateCouponDefinition', 'couponDefinitionId', couponDefinitionId)
            // verify required parameter 'updateCouponDefinitionDTO' is not null or undefined
            assertParamExists('updateCouponDefinition', 'updateCouponDefinitionDTO', updateCouponDefinitionDTO)
            const localVarPath = `/benefits/coupons/definitions/{couponDefinitionId}`
                .replace(`{${"couponDefinitionId"}}`, encodeURIComponent(String(couponDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCouponDefinitionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the default coupon of an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeCouponDefaultDTO} updateEmployeeCouponDefaultDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponEmployeeDefault: async (tenantId: string, employeeId: string, updateEmployeeCouponDefaultDTO: UpdateEmployeeCouponDefaultDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateCouponEmployeeDefault', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateCouponEmployeeDefault', 'employeeId', employeeId)
            // verify required parameter 'updateEmployeeCouponDefaultDTO' is not null or undefined
            assertParamExists('updateCouponEmployeeDefault', 'updateEmployeeCouponDefaultDTO', updateEmployeeCouponDefaultDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/default`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeCouponDefaultDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {UpdateCouponGiftDTO} updateCouponGiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponGift: async (tenantId: string, employeeId: string, couponGiftId: string, updateCouponGiftDTO: UpdateCouponGiftDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateCouponGift', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateCouponGift', 'employeeId', employeeId)
            // verify required parameter 'couponGiftId' is not null or undefined
            assertParamExists('updateCouponGift', 'couponGiftId', couponGiftId)
            // verify required parameter 'updateCouponGiftDTO' is not null or undefined
            assertParamExists('updateCouponGift', 'updateCouponGiftDTO', updateCouponGiftDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/gifts/{couponGiftId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponGiftId"}}`, encodeURIComponent(String(couponGiftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCouponGiftDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a coupon order for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {UpdateCouponOrderDTO} updateCouponOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponOrder: async (tenantId: string, employeeId: string, couponOrderId: string, updateCouponOrderDTO: UpdateCouponOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateCouponOrder', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateCouponOrder', 'employeeId', employeeId)
            // verify required parameter 'couponOrderId' is not null or undefined
            assertParamExists('updateCouponOrder', 'couponOrderId', couponOrderId)
            // verify required parameter 'updateCouponOrderDTO' is not null or undefined
            assertParamExists('updateCouponOrder', 'updateCouponOrderDTO', updateCouponOrderDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/coupons/orders/{couponOrderId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"couponOrderId"}}`, encodeURIComponent(String(couponOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCouponOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary cancels a pending coupon order
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelCouponOrder(tenantId: string, employeeId: string, couponOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelCouponOrder(tenantId, employeeId, couponOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary places a coupon budget order
         * @param {string} tenantId 
         * @param {CreateCouponBudgetOrderDTO} createCouponBudgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBudgetOrder(tenantId: string, createCouponBudgetOrderDTO: CreateCouponBudgetOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBudgetOrder(tenantId, createCouponBudgetOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponGiftDTO} createCouponGiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCouponGift(tenantId: string, employeeId: string, createCouponGiftDTO: CreateCouponGiftDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCouponGift(tenantId, employeeId, createCouponGiftDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a coupon order for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponOrderDTO} createCouponOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCouponOrder(tenantId: string, employeeId: string, createCouponOrderDTO: CreateCouponOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCouponOrder(tenantId, employeeId, createCouponOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an override for the next possible date
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCouponOverride(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCouponOverride(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCouponGift(tenantId: string, employeeId: string, couponGiftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCouponGift(tenantId, employeeId, couponGiftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the end date to the current coupon override
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} overrideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endOverride(tenantId: string, employeeId: string, overrideId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endOverride(tenantId, employeeId, overrideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the whole transaction history list in CSV format
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {boolean} [includeCouponOrders] 
         * @param {boolean} [includeGiftOrders] 
         * @param {boolean} [includeBudgetOrders] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTransactionHistory(tenantId: string, month?: string, includeCouponOrders?: boolean, includeGiftOrders?: boolean, includeBudgetOrders?: boolean, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTransactionHistory(tenantId, month, includeCouponOrders, includeGiftOrders, includeBudgetOrders, filter, withAggregations, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all coupons in the system
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminCouponDefinitions(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCouponDefinitionListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminCouponDefinitions(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all coupons that a user can explore
         * @param {string} tenantId 
         * @param {boolean} [includeVariations] 
         * @param {number} [variation] 
         * @param {Array<number>} [variations] 
         * @param {string} [category] 
         * @param {boolean} [includeCombinations] 
         * @param {boolean} [isFallback] 
         * @param {boolean} [isFlexFallback] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCouponDefinitions(tenantId: string, includeVariations?: boolean, variation?: number, variations?: Array<number>, category?: string, includeCombinations?: boolean, isFallback?: boolean, isFlexFallback?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponDefinitionListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCouponDefinitions(tenantId, includeVariations, variation, variations, category, includeCombinations, isFallback, isFlexFallback, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all budget orders for all tenants
         * @param {'ORDERED' | 'COMPLETED' | 'CANCELLED'} [budgetOrderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBudgetOrders(budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED', page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponBudgetOrderWithTenantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBudgetOrders(budgetOrderStatus, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all budget orders
         * @param {string} tenantId 
         * @param {'ORDERED' | 'COMPLETED' | 'CANCELLED'} [budgetOrderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBudgetOrders(tenantId: string, budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED', page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponBudgetOrdersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBudgetOrders(tenantId, budgetOrderStatus, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single coupon definition
         * @param {string} tenantId 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponDefinition(tenantId: string, couponId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponDefinitionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponDefinition(tenantId, couponId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single coupon gift by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponGift(tenantId: string, employeeId: string, couponGiftId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponGiftDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponGift(tenantId, employeeId, couponGiftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a single coupon order by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponOrder(tenantId: string, employeeId: string, couponOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponOrderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponOrder(tenantId, employeeId, couponOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupon orders for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponOrders(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponOrderListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponOrders(tenantId, employeeId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns the statistic for coupons of one tenant for one month
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponOrdersStatistic(tenantId: string, month?: string, status?: 'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponOrdersStatisticDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponOrdersStatistic(tenantId, month, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all custom gift occasions for the tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGiftOccasions(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponGiftOccasionListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGiftOccasions(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary this will return the whole transaction history list
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {boolean} [includeCouponOrders] 
         * @param {boolean} [includeGiftOrders] 
         * @param {boolean} [includeBudgetOrders] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionHistory(tenantId: string, month?: string, includeCouponOrders?: boolean, includeGiftOrders?: boolean, includeBudgetOrders?: boolean, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponTransactionsHistoryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionHistory(tenantId, month, includeCouponOrders, includeGiftOrders, includeBudgetOrders, filter, withAggregations, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all orders for all tenants
         * @param {Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [isAutomatedOrder] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllOrders(status?: Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>, filter?: string, isAutomatedOrder?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponOrderWithEmployeeListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllOrders(status, filter, isAutomatedOrder, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupon gifts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCouponGifts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponGiftListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCouponGifts(tenantId, employeeId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns coupon gifts for tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [isDelivered] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenantCouponGifts(tenantId: string, filter?: string, isDelivered?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponGiftWithEmployeeListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenantCouponGifts(tenantId, filter, isDelivered, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a gift occasion for the tenant
         * @param {string} tenantId 
         * @param {'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER'} occasion 
         * @param {SaveGiftOccasionDTO} saveGiftOccasionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGiftOccasion(tenantId: string, occasion: 'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER', saveGiftOccasionDTO: SaveGiftOccasionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGiftOccasion(tenantId, occasion, saveGiftOccasionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sets coupon code to seen
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCouponCodeToSeen(tenantId: string, employeeId: string, couponOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCouponCodeToSeen(tenantId, employeeId, couponOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a coupon budget order
         * @param {string} tenantId 
         * @param {string} couponBudgetOrderId 
         * @param {UpdateCouponBudgetOrderDTO} updateCouponBudgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBudgetOrder(tenantId: string, couponBudgetOrderId: string, updateCouponBudgetOrderDTO: UpdateCouponBudgetOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBudgetOrder(tenantId, couponBudgetOrderId, updateCouponBudgetOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a coupon definition
         * @param {string} couponDefinitionId 
         * @param {UpdateCouponDefinitionDTO} updateCouponDefinitionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCouponDefinition(couponDefinitionId: string, updateCouponDefinitionDTO: UpdateCouponDefinitionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCouponDefinition(couponDefinitionId, updateCouponDefinitionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the default coupon of an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeCouponDefaultDTO} updateEmployeeCouponDefaultDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCouponEmployeeDefault(tenantId: string, employeeId: string, updateEmployeeCouponDefaultDTO: UpdateEmployeeCouponDefaultDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCouponEmployeeDefault(tenantId, employeeId, updateEmployeeCouponDefaultDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {UpdateCouponGiftDTO} updateCouponGiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCouponGift(tenantId: string, employeeId: string, couponGiftId: string, updateCouponGiftDTO: UpdateCouponGiftDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCouponGift(tenantId, employeeId, couponGiftId, updateCouponGiftDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a coupon order for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {UpdateCouponOrderDTO} updateCouponOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCouponOrder(tenantId: string, employeeId: string, couponOrderId: string, updateCouponOrderDTO: UpdateCouponOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCouponOrder(tenantId, employeeId, couponOrderId, updateCouponOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsApiFp(configuration)
    return {
        /**
         * 
         * @summary cancels a pending coupon order
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCouponOrder(tenantId: string, employeeId: string, couponOrderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelCouponOrder(tenantId, employeeId, couponOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary places a coupon budget order
         * @param {string} tenantId 
         * @param {CreateCouponBudgetOrderDTO} createCouponBudgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBudgetOrder(tenantId: string, createCouponBudgetOrderDTO: CreateCouponBudgetOrderDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createBudgetOrder(tenantId, createCouponBudgetOrderDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponGiftDTO} createCouponGiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponGift(tenantId: string, employeeId: string, createCouponGiftDTO: CreateCouponGiftDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createCouponGift(tenantId, employeeId, createCouponGiftDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a coupon order for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateCouponOrderDTO} createCouponOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOrder(tenantId: string, employeeId: string, createCouponOrderDTO: CreateCouponOrderDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createCouponOrder(tenantId, employeeId, createCouponOrderDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an override for the next possible date
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOverride(tenantId: string, employeeId: string, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createCouponOverride(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCouponGift(tenantId: string, employeeId: string, couponGiftId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCouponGift(tenantId, employeeId, couponGiftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the end date to the current coupon override
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} overrideId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endOverride(tenantId: string, employeeId: string, overrideId: string, options?: any): AxiosPromise<void> {
            return localVarFp.endOverride(tenantId, employeeId, overrideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the whole transaction history list in CSV format
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {boolean} [includeCouponOrders] 
         * @param {boolean} [includeGiftOrders] 
         * @param {boolean} [includeBudgetOrders] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTransactionHistory(tenantId: string, month?: string, includeCouponOrders?: boolean, includeGiftOrders?: boolean, includeBudgetOrders?: boolean, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<string> {
            return localVarFp.exportTransactionHistory(tenantId, month, includeCouponOrders, includeGiftOrders, includeBudgetOrders, filter, withAggregations, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all coupons in the system
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCouponDefinitions(page?: number, pageSize?: number, options?: any): AxiosPromise<AdminCouponDefinitionListDTO> {
            return localVarFp.findAdminCouponDefinitions(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all coupons that a user can explore
         * @param {string} tenantId 
         * @param {boolean} [includeVariations] 
         * @param {number} [variation] 
         * @param {Array<number>} [variations] 
         * @param {string} [category] 
         * @param {boolean} [includeCombinations] 
         * @param {boolean} [isFallback] 
         * @param {boolean} [isFlexFallback] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCouponDefinitions(tenantId: string, includeVariations?: boolean, variation?: number, variations?: Array<number>, category?: string, includeCombinations?: boolean, isFallback?: boolean, isFlexFallback?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponDefinitionListDTO> {
            return localVarFp.findCouponDefinitions(tenantId, includeVariations, variation, variations, category, includeCombinations, isFallback, isFlexFallback, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all budget orders for all tenants
         * @param {'ORDERED' | 'COMPLETED' | 'CANCELLED'} [budgetOrderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBudgetOrders(budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED', page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<CouponBudgetOrderWithTenantListDTO> {
            return localVarFp.getAllBudgetOrders(budgetOrderStatus, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all budget orders
         * @param {string} tenantId 
         * @param {'ORDERED' | 'COMPLETED' | 'CANCELLED'} [budgetOrderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBudgetOrders(tenantId: string, budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED', page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<CouponBudgetOrdersDTO> {
            return localVarFp.getBudgetOrders(tenantId, budgetOrderStatus, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single coupon definition
         * @param {string} tenantId 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponDefinition(tenantId: string, couponId: string, options?: any): AxiosPromise<CouponDefinitionDTO> {
            return localVarFp.getCouponDefinition(tenantId, couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single coupon gift by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponGift(tenantId: string, employeeId: string, couponGiftId: string, options?: any): AxiosPromise<CouponGiftDTO> {
            return localVarFp.getCouponGift(tenantId, employeeId, couponGiftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a single coupon order by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponOrder(tenantId: string, employeeId: string, couponOrderId: string, options?: any): AxiosPromise<CouponOrderDTO> {
            return localVarFp.getCouponOrder(tenantId, employeeId, couponOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupon orders for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponOrders(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponOrderListDTO> {
            return localVarFp.getCouponOrders(tenantId, employeeId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns the statistic for coupons of one tenant for one month
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponOrdersStatistic(tenantId: string, month?: string, status?: 'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED', options?: any): AxiosPromise<CouponOrdersStatisticDTO> {
            return localVarFp.getCouponOrdersStatistic(tenantId, month, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all custom gift occasions for the tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGiftOccasions(tenantId: string, options?: any): AxiosPromise<CouponGiftOccasionListDTO> {
            return localVarFp.getGiftOccasions(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary this will return the whole transaction history list
         * @param {string} tenantId 
         * @param {string} [month] 
         * @param {boolean} [includeCouponOrders] 
         * @param {boolean} [includeGiftOrders] 
         * @param {boolean} [includeBudgetOrders] 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionHistory(tenantId: string, month?: string, includeCouponOrders?: boolean, includeGiftOrders?: boolean, includeBudgetOrders?: boolean, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponTransactionsHistoryDTO> {
            return localVarFp.getTransactionHistory(tenantId, month, includeCouponOrders, includeGiftOrders, includeBudgetOrders, filter, withAggregations, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all orders for all tenants
         * @param {Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>} [status] 
         * @param {string} [filter] 
         * @param {boolean} [isAutomatedOrder] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllOrders(status?: Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>, filter?: string, isAutomatedOrder?: boolean, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<CouponOrderWithEmployeeListDTO> {
            return localVarFp.listAllOrders(status, filter, isAutomatedOrder, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupon gifts for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCouponGifts(tenantId: string, employeeId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponGiftListDTO> {
            return localVarFp.listCouponGifts(tenantId, employeeId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns coupon gifts for tenant
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [isDelivered] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantCouponGifts(tenantId: string, filter?: string, isDelivered?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<CouponGiftWithEmployeeListDTO> {
            return localVarFp.listTenantCouponGifts(tenantId, filter, isDelivered, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a gift occasion for the tenant
         * @param {string} tenantId 
         * @param {'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER'} occasion 
         * @param {SaveGiftOccasionDTO} saveGiftOccasionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGiftOccasion(tenantId: string, occasion: 'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER', saveGiftOccasionDTO: SaveGiftOccasionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.saveGiftOccasion(tenantId, occasion, saveGiftOccasionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sets coupon code to seen
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCouponCodeToSeen(tenantId: string, employeeId: string, couponOrderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setCouponCodeToSeen(tenantId, employeeId, couponOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a coupon budget order
         * @param {string} tenantId 
         * @param {string} couponBudgetOrderId 
         * @param {UpdateCouponBudgetOrderDTO} updateCouponBudgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBudgetOrder(tenantId: string, couponBudgetOrderId: string, updateCouponBudgetOrderDTO: UpdateCouponBudgetOrderDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateBudgetOrder(tenantId, couponBudgetOrderId, updateCouponBudgetOrderDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a coupon definition
         * @param {string} couponDefinitionId 
         * @param {UpdateCouponDefinitionDTO} updateCouponDefinitionDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponDefinition(couponDefinitionId: string, updateCouponDefinitionDTO: UpdateCouponDefinitionDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateCouponDefinition(couponDefinitionId, updateCouponDefinitionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the default coupon of an employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeCouponDefaultDTO} updateEmployeeCouponDefaultDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponEmployeeDefault(tenantId: string, employeeId: string, updateEmployeeCouponDefaultDTO: UpdateEmployeeCouponDefaultDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateCouponEmployeeDefault(tenantId, employeeId, updateEmployeeCouponDefaultDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a coupon gift for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponGiftId 
         * @param {UpdateCouponGiftDTO} updateCouponGiftDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponGift(tenantId: string, employeeId: string, couponGiftId: string, updateCouponGiftDTO: UpdateCouponGiftDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateCouponGift(tenantId, employeeId, couponGiftId, updateCouponGiftDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a coupon order for employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} couponOrderId 
         * @param {UpdateCouponOrderDTO} updateCouponOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponOrder(tenantId: string, employeeId: string, couponOrderId: string, updateCouponOrderDTO: UpdateCouponOrderDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateCouponOrder(tenantId, employeeId, couponOrderId, updateCouponOrderDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelCouponOrder operation in CouponsApi.
 * @export
 * @interface CouponsApiCancelCouponOrderRequest
 */
export interface CouponsApiCancelCouponOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiCancelCouponOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiCancelCouponOrder
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiCancelCouponOrder
     */
    readonly couponOrderId: string
}

/**
 * Request parameters for createBudgetOrder operation in CouponsApi.
 * @export
 * @interface CouponsApiCreateBudgetOrderRequest
 */
export interface CouponsApiCreateBudgetOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateBudgetOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateCouponBudgetOrderDTO}
     * @memberof CouponsApiCreateBudgetOrder
     */
    readonly createCouponBudgetOrderDTO: CreateCouponBudgetOrderDTO
}

/**
 * Request parameters for createCouponGift operation in CouponsApi.
 * @export
 * @interface CouponsApiCreateCouponGiftRequest
 */
export interface CouponsApiCreateCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateCouponGiftDTO}
     * @memberof CouponsApiCreateCouponGift
     */
    readonly createCouponGiftDTO: CreateCouponGiftDTO
}

/**
 * Request parameters for createCouponOrder operation in CouponsApi.
 * @export
 * @interface CouponsApiCreateCouponOrderRequest
 */
export interface CouponsApiCreateCouponOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateCouponOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateCouponOrder
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateCouponOrderDTO}
     * @memberof CouponsApiCreateCouponOrder
     */
    readonly createCouponOrderDTO: CreateCouponOrderDTO
}

/**
 * Request parameters for createCouponOverride operation in CouponsApi.
 * @export
 * @interface CouponsApiCreateCouponOverrideRequest
 */
export interface CouponsApiCreateCouponOverrideRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateCouponOverride
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiCreateCouponOverride
     */
    readonly employeeId: string
}

/**
 * Request parameters for deleteCouponGift operation in CouponsApi.
 * @export
 * @interface CouponsApiDeleteCouponGiftRequest
 */
export interface CouponsApiDeleteCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiDeleteCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiDeleteCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiDeleteCouponGift
     */
    readonly couponGiftId: string
}

/**
 * Request parameters for endOverride operation in CouponsApi.
 * @export
 * @interface CouponsApiEndOverrideRequest
 */
export interface CouponsApiEndOverrideRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiEndOverride
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiEndOverride
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiEndOverride
     */
    readonly overrideId: string
}

/**
 * Request parameters for exportTransactionHistory operation in CouponsApi.
 * @export
 * @interface CouponsApiExportTransactionHistoryRequest
 */
export interface CouponsApiExportTransactionHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly month?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly includeCouponOrders?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly includeGiftOrders?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly includeBudgetOrders?: boolean

    /**
     * 
     * @type {string}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly withAggregations?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiExportTransactionHistory
     */
    readonly pageSize?: number
}

/**
 * Request parameters for findAdminCouponDefinitions operation in CouponsApi.
 * @export
 * @interface CouponsApiFindAdminCouponDefinitionsRequest
 */
export interface CouponsApiFindAdminCouponDefinitionsRequest {
    /**
     * 
     * @type {number}
     * @memberof CouponsApiFindAdminCouponDefinitions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiFindAdminCouponDefinitions
     */
    readonly pageSize?: number
}

/**
 * Request parameters for findCouponDefinitions operation in CouponsApi.
 * @export
 * @interface CouponsApiFindCouponDefinitionsRequest
 */
export interface CouponsApiFindCouponDefinitionsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly tenantId: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly includeVariations?: boolean

    /**
     * 
     * @type {number}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly variation?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly variations?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly category?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly includeCombinations?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly isFallback?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly isFlexFallback?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiFindCouponDefinitions
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllBudgetOrders operation in CouponsApi.
 * @export
 * @interface CouponsApiGetAllBudgetOrdersRequest
 */
export interface CouponsApiGetAllBudgetOrdersRequest {
    /**
     * 
     * @type {'ORDERED' | 'COMPLETED' | 'CANCELLED'}
     * @memberof CouponsApiGetAllBudgetOrders
     */
    readonly budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED'

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetAllBudgetOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetAllBudgetOrders
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiGetAllBudgetOrders
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for getBudgetOrders operation in CouponsApi.
 * @export
 * @interface CouponsApiGetBudgetOrdersRequest
 */
export interface CouponsApiGetBudgetOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetBudgetOrders
     */
    readonly tenantId: string

    /**
     * 
     * @type {'ORDERED' | 'COMPLETED' | 'CANCELLED'}
     * @memberof CouponsApiGetBudgetOrders
     */
    readonly budgetOrderStatus?: 'ORDERED' | 'COMPLETED' | 'CANCELLED'

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetBudgetOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetBudgetOrders
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiGetBudgetOrders
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for getCouponDefinition operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponDefinitionRequest
 */
export interface CouponsApiGetCouponDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponDefinition
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponDefinition
     */
    readonly couponId: string
}

/**
 * Request parameters for getCouponGift operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponGiftRequest
 */
export interface CouponsApiGetCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponGift
     */
    readonly couponGiftId: string
}

/**
 * Request parameters for getCouponOrder operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponOrderRequest
 */
export interface CouponsApiGetCouponOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrder
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrder
     */
    readonly couponOrderId: string
}

/**
 * Request parameters for getCouponOrders operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponOrdersRequest
 */
export interface CouponsApiGetCouponOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrders
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrders
     */
    readonly employeeId: string

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetCouponOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetCouponOrders
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getCouponOrdersStatistic operation in CouponsApi.
 * @export
 * @interface CouponsApiGetCouponOrdersStatisticRequest
 */
export interface CouponsApiGetCouponOrdersStatisticRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrdersStatistic
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetCouponOrdersStatistic
     */
    readonly month?: string

    /**
     * 
     * @type {'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'}
     * @memberof CouponsApiGetCouponOrdersStatistic
     */
    readonly status?: 'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'
}

/**
 * Request parameters for getGiftOccasions operation in CouponsApi.
 * @export
 * @interface CouponsApiGetGiftOccasionsRequest
 */
export interface CouponsApiGetGiftOccasionsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetGiftOccasions
     */
    readonly tenantId: string
}

/**
 * Request parameters for getTransactionHistory operation in CouponsApi.
 * @export
 * @interface CouponsApiGetTransactionHistoryRequest
 */
export interface CouponsApiGetTransactionHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly month?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly includeCouponOrders?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly includeGiftOrders?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly includeBudgetOrders?: boolean

    /**
     * 
     * @type {string}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly withAggregations?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiGetTransactionHistory
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listAllOrders operation in CouponsApi.
 * @export
 * @interface CouponsApiListAllOrdersRequest
 */
export interface CouponsApiListAllOrdersRequest {
    /**
     * 
     * @type {Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>}
     * @memberof CouponsApiListAllOrders
     */
    readonly status?: Array<'PAYMENT_DUE' | 'PENDING' | 'ORDERED' | 'COMPLETED' | 'ERROR' | 'PAYMENT_FAILED'>

    /**
     * 
     * @type {string}
     * @memberof CouponsApiListAllOrders
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiListAllOrders
     */
    readonly isAutomatedOrder?: boolean

    /**
     * 
     * @type {number}
     * @memberof CouponsApiListAllOrders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiListAllOrders
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiListAllOrders
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for listCouponGifts operation in CouponsApi.
 * @export
 * @interface CouponsApiListCouponGiftsRequest
 */
export interface CouponsApiListCouponGiftsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiListCouponGifts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiListCouponGifts
     */
    readonly employeeId: string

    /**
     * 
     * @type {number}
     * @memberof CouponsApiListCouponGifts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiListCouponGifts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTenantCouponGifts operation in CouponsApi.
 * @export
 * @interface CouponsApiListTenantCouponGiftsRequest
 */
export interface CouponsApiListTenantCouponGiftsRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiListTenantCouponGifts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiListTenantCouponGifts
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof CouponsApiListTenantCouponGifts
     */
    readonly isDelivered?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof CouponsApiListTenantCouponGifts
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof CouponsApiListTenantCouponGifts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CouponsApiListTenantCouponGifts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for saveGiftOccasion operation in CouponsApi.
 * @export
 * @interface CouponsApiSaveGiftOccasionRequest
 */
export interface CouponsApiSaveGiftOccasionRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiSaveGiftOccasion
     */
    readonly tenantId: string

    /**
     * 
     * @type {'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER'}
     * @memberof CouponsApiSaveGiftOccasion
     */
    readonly occasion: 'BIRTHDAY' | 'SAINTS_DAY' | 'CHILD_BIRTH' | 'CHILD_BAPTISM' | 'CHILD_COMMUNION' | 'CHILD_CONFIRMATION_CATH' | 'CHILD_CONFIRMATION_PROT' | 'WEDDING' | 'WEDDING_SILVER' | 'WEDDING_GOLD' | 'PASSED_EXAM' | 'COMPANY_ANNIVERSARY' | 'ACHIEVEMENT' | 'WORK_PERFORMANCE' | 'COMMITMENT' | 'PROPOSAL' | 'RECRUITMENT' | 'ATTENDANCE' | 'SIGNING_BONUS' | 'PROFIT_SHARING' | 'OTHER'

    /**
     * 
     * @type {SaveGiftOccasionDTO}
     * @memberof CouponsApiSaveGiftOccasion
     */
    readonly saveGiftOccasionDTO: SaveGiftOccasionDTO
}

/**
 * Request parameters for setCouponCodeToSeen operation in CouponsApi.
 * @export
 * @interface CouponsApiSetCouponCodeToSeenRequest
 */
export interface CouponsApiSetCouponCodeToSeenRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiSetCouponCodeToSeen
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiSetCouponCodeToSeen
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiSetCouponCodeToSeen
     */
    readonly couponOrderId: string
}

/**
 * Request parameters for updateBudgetOrder operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateBudgetOrderRequest
 */
export interface CouponsApiUpdateBudgetOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateBudgetOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateBudgetOrder
     */
    readonly couponBudgetOrderId: string

    /**
     * 
     * @type {UpdateCouponBudgetOrderDTO}
     * @memberof CouponsApiUpdateBudgetOrder
     */
    readonly updateCouponBudgetOrderDTO: UpdateCouponBudgetOrderDTO
}

/**
 * Request parameters for updateCouponDefinition operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateCouponDefinitionRequest
 */
export interface CouponsApiUpdateCouponDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponDefinition
     */
    readonly couponDefinitionId: string

    /**
     * 
     * @type {UpdateCouponDefinitionDTO}
     * @memberof CouponsApiUpdateCouponDefinition
     */
    readonly updateCouponDefinitionDTO: UpdateCouponDefinitionDTO
}

/**
 * Request parameters for updateCouponEmployeeDefault operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateCouponEmployeeDefaultRequest
 */
export interface CouponsApiUpdateCouponEmployeeDefaultRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponEmployeeDefault
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponEmployeeDefault
     */
    readonly employeeId: string

    /**
     * 
     * @type {UpdateEmployeeCouponDefaultDTO}
     * @memberof CouponsApiUpdateCouponEmployeeDefault
     */
    readonly updateEmployeeCouponDefaultDTO: UpdateEmployeeCouponDefaultDTO
}

/**
 * Request parameters for updateCouponGift operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateCouponGiftRequest
 */
export interface CouponsApiUpdateCouponGiftRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponGift
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponGift
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponGift
     */
    readonly couponGiftId: string

    /**
     * 
     * @type {UpdateCouponGiftDTO}
     * @memberof CouponsApiUpdateCouponGift
     */
    readonly updateCouponGiftDTO: UpdateCouponGiftDTO
}

/**
 * Request parameters for updateCouponOrder operation in CouponsApi.
 * @export
 * @interface CouponsApiUpdateCouponOrderRequest
 */
export interface CouponsApiUpdateCouponOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponOrder
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponOrder
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof CouponsApiUpdateCouponOrder
     */
    readonly couponOrderId: string

    /**
     * 
     * @type {UpdateCouponOrderDTO}
     * @memberof CouponsApiUpdateCouponOrder
     */
    readonly updateCouponOrderDTO: UpdateCouponOrderDTO
}

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     * 
     * @summary cancels a pending coupon order
     * @param {CouponsApiCancelCouponOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public cancelCouponOrder(requestParameters: CouponsApiCancelCouponOrderRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).cancelCouponOrder(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary places a coupon budget order
     * @param {CouponsApiCreateBudgetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public createBudgetOrder(requestParameters: CouponsApiCreateBudgetOrderRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).createBudgetOrder(requestParameters.tenantId, requestParameters.createCouponBudgetOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a coupon gift for employee
     * @param {CouponsApiCreateCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public createCouponGift(requestParameters: CouponsApiCreateCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).createCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createCouponGiftDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a coupon order for employee
     * @param {CouponsApiCreateCouponOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public createCouponOrder(requestParameters: CouponsApiCreateCouponOrderRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).createCouponOrder(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createCouponOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an override for the next possible date
     * @param {CouponsApiCreateCouponOverrideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public createCouponOverride(requestParameters: CouponsApiCreateCouponOverrideRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).createCouponOverride(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a coupon gift for employee
     * @param {CouponsApiDeleteCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public deleteCouponGift(requestParameters: CouponsApiDeleteCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).deleteCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponGiftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the end date to the current coupon override
     * @param {CouponsApiEndOverrideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public endOverride(requestParameters: CouponsApiEndOverrideRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).endOverride(requestParameters.tenantId, requestParameters.employeeId, requestParameters.overrideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the whole transaction history list in CSV format
     * @param {CouponsApiExportTransactionHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public exportTransactionHistory(requestParameters: CouponsApiExportTransactionHistoryRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).exportTransactionHistory(requestParameters.tenantId, requestParameters.month, requestParameters.includeCouponOrders, requestParameters.includeGiftOrders, requestParameters.includeBudgetOrders, requestParameters.filter, requestParameters.withAggregations, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all coupons in the system
     * @param {CouponsApiFindAdminCouponDefinitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public findAdminCouponDefinitions(requestParameters: CouponsApiFindAdminCouponDefinitionsRequest = {}, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).findAdminCouponDefinitions(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all coupons that a user can explore
     * @param {CouponsApiFindCouponDefinitionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public findCouponDefinitions(requestParameters: CouponsApiFindCouponDefinitionsRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).findCouponDefinitions(requestParameters.tenantId, requestParameters.includeVariations, requestParameters.variation, requestParameters.variations, requestParameters.category, requestParameters.includeCombinations, requestParameters.isFallback, requestParameters.isFlexFallback, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all budget orders for all tenants
     * @param {CouponsApiGetAllBudgetOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getAllBudgetOrders(requestParameters: CouponsApiGetAllBudgetOrdersRequest = {}, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getAllBudgetOrders(requestParameters.budgetOrderStatus, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all budget orders
     * @param {CouponsApiGetBudgetOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getBudgetOrders(requestParameters: CouponsApiGetBudgetOrdersRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getBudgetOrders(requestParameters.tenantId, requestParameters.budgetOrderStatus, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single coupon definition
     * @param {CouponsApiGetCouponDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCouponDefinition(requestParameters: CouponsApiGetCouponDefinitionRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCouponDefinition(requestParameters.tenantId, requestParameters.couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single coupon gift by id
     * @param {CouponsApiGetCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCouponGift(requestParameters: CouponsApiGetCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponGiftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a single coupon order by id
     * @param {CouponsApiGetCouponOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCouponOrder(requestParameters: CouponsApiGetCouponOrderRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCouponOrder(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupon orders for employee
     * @param {CouponsApiGetCouponOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCouponOrders(requestParameters: CouponsApiGetCouponOrdersRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCouponOrders(requestParameters.tenantId, requestParameters.employeeId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns the statistic for coupons of one tenant for one month
     * @param {CouponsApiGetCouponOrdersStatisticRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getCouponOrdersStatistic(requestParameters: CouponsApiGetCouponOrdersStatisticRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getCouponOrdersStatistic(requestParameters.tenantId, requestParameters.month, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all custom gift occasions for the tenant
     * @param {CouponsApiGetGiftOccasionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getGiftOccasions(requestParameters: CouponsApiGetGiftOccasionsRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getGiftOccasions(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary this will return the whole transaction history list
     * @param {CouponsApiGetTransactionHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public getTransactionHistory(requestParameters: CouponsApiGetTransactionHistoryRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).getTransactionHistory(requestParameters.tenantId, requestParameters.month, requestParameters.includeCouponOrders, requestParameters.includeGiftOrders, requestParameters.includeBudgetOrders, requestParameters.filter, requestParameters.withAggregations, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all orders for all tenants
     * @param {CouponsApiListAllOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public listAllOrders(requestParameters: CouponsApiListAllOrdersRequest = {}, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).listAllOrders(requestParameters.status, requestParameters.filter, requestParameters.isAutomatedOrder, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupon gifts for employee
     * @param {CouponsApiListCouponGiftsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public listCouponGifts(requestParameters: CouponsApiListCouponGiftsRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).listCouponGifts(requestParameters.tenantId, requestParameters.employeeId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns coupon gifts for tenant
     * @param {CouponsApiListTenantCouponGiftsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public listTenantCouponGifts(requestParameters: CouponsApiListTenantCouponGiftsRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).listTenantCouponGifts(requestParameters.tenantId, requestParameters.filter, requestParameters.isDelivered, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a gift occasion for the tenant
     * @param {CouponsApiSaveGiftOccasionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public saveGiftOccasion(requestParameters: CouponsApiSaveGiftOccasionRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).saveGiftOccasion(requestParameters.tenantId, requestParameters.occasion, requestParameters.saveGiftOccasionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sets coupon code to seen
     * @param {CouponsApiSetCouponCodeToSeenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public setCouponCodeToSeen(requestParameters: CouponsApiSetCouponCodeToSeenRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).setCouponCodeToSeen(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a coupon budget order
     * @param {CouponsApiUpdateBudgetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateBudgetOrder(requestParameters: CouponsApiUpdateBudgetOrderRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateBudgetOrder(requestParameters.tenantId, requestParameters.couponBudgetOrderId, requestParameters.updateCouponBudgetOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a coupon definition
     * @param {CouponsApiUpdateCouponDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateCouponDefinition(requestParameters: CouponsApiUpdateCouponDefinitionRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateCouponDefinition(requestParameters.couponDefinitionId, requestParameters.updateCouponDefinitionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the default coupon of an employee
     * @param {CouponsApiUpdateCouponEmployeeDefaultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateCouponEmployeeDefault(requestParameters: CouponsApiUpdateCouponEmployeeDefaultRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateCouponEmployeeDefault(requestParameters.tenantId, requestParameters.employeeId, requestParameters.updateEmployeeCouponDefaultDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a coupon gift for employee
     * @param {CouponsApiUpdateCouponGiftRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateCouponGift(requestParameters: CouponsApiUpdateCouponGiftRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateCouponGift(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponGiftId, requestParameters.updateCouponGiftDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a coupon order for employee
     * @param {CouponsApiUpdateCouponOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public updateCouponOrder(requestParameters: CouponsApiUpdateCouponOrderRequest, options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).updateCouponOrder(requestParameters.tenantId, requestParameters.employeeId, requestParameters.couponOrderId, requestParameters.updateCouponOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
