import { Box, Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { FitnessActionButton } from '../FitnessActionButton';
import { useFitnessState } from '../useFitnessState';
import FitnessPanel from './FitnessPanel';

export const FitnessPendingPanel: React.FC = () => {
  const { registrationLink } = useFitnessState();
  return (
    <>
      <FitnessActionButton disabled={true} />
      <FitnessPanel>
        <Stack alignItems={'center'} spacing={1} p={1}>
          <Box sx={{ fontSize: 100 }}>🎉</Box>
          <Typography variant="h3" textAlign={'center'}>
            <Trans i18nKey="benefitModule:benefit.FITNESS.PENDING.info" />
          </Typography>
        </Stack>
      </FitnessPanel>
      <Stack p={1}>
        <Button variant="outlined" fullWidth href={registrationLink} target="_blank">
          {t('benefitModule:benefit.FITNESS.PENDING.actionLink')}
        </Button>
      </Stack>
    </>
  );
};

export default FitnessPendingPanel;
