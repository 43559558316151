import { Box, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { FitnessActionButton } from '../FitnessActionButton';
import FitnessPanel from './FitnessPanel';

export const FitnessExpiringPanel = () => {
  return (
    <>
      <FitnessActionButton disabled={true} />
      <FitnessPanel>
        <Stack alignItems={'center'} spacing={1} p={1}>
          <Box sx={{ fontSize: 100 }}>👍</Box>
          <Typography variant="h3" textAlign="center">
            <Trans i18nKey="benefitModule:benefit.FITNESS.EXPIRING.info" />
          </Typography>
        </Stack>
      </FitnessPanel>
    </>
  );
};

export default FitnessExpiringPanel;
