import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import FitnessPendingPanel from '../../module/fitness/panels/FitnessPendingPanel';
import FitnessActivePanel from '../../module/fitness/panels/FitnessActivePanel';
import FitnessExpiringPanel from '../../module/fitness/panels/FitnessExpiringPanel';
import { FitnessInactivePanel } from '../../module/fitness/panels/FitnessInactivePanel';
import FitnessRegisteringPanel from '../../module/fitness/panels/FitnessRegisteringPanel';
import { FitnessStateProvider, FitnessStatusConfig, useFitnessQuery, useFitnessState } from '../../module/fitness/useFitnessState';
import { FitnessBenefitDetailsDTOStatusEnum } from 'probonio-shared-ui/api';
import { DateTime } from 'luxon';
import FitnessTooLatePanel from '../../module/fitness/panels/FitnessTooLatePanel';

export const FitnessPage: React.FC = () => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');
  const navigate = useNavigate();
  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);
  const { status, isLoading, startDate, endDate, monthlyBudget: budget } = useFitnessQuery();

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <AppToolbar
          title={t('benefit.FITNESS.name')}
          color={theme.palette.primary.contrastText}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <HighlightGuideButton
              name={t('benefit.FITNESS.name')}
              header={t('benefit.FITNESS.firstHelpGuide.header')}
              text={t('benefit.FITNESS.firstHelpGuide.text')}
              onClick={navigateInfo}
            />
          }
        />
      }
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box pb={0.5} sx={{ backgroundColor: theme.palette.background.paper }}>
            <Typography color="text.secondary" textAlign="center">
              {t(`benefit.FITNESS.headerBudget.name`)}
              <Typography fontWeight="bold" textAlign="center" component="span">
                {t(`benefit.FITNESS.headerBudget.amount`, { budget })}
              </Typography>
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              {t(`benefit.FITNESS.headerStatus.name`)}
              <Typography
                color={
                  status === FitnessBenefitDetailsDTOStatusEnum.Active || status === FitnessBenefitDetailsDTOStatusEnum.Pending
                    ? 'success.main'
                    : 'text.secondary'
                }
                fontWeight="bold"
                textAlign="center"
                component="span"
              >
                {t(`benefit.FITNESS.headerStatus.${status}`, {
                  startDate: startDate && new Date(startDate),
                  endDate: endDate && DateTime.fromJSDate(endDate).plus({ month: 1 }).toJSDate(),
                })}
              </Typography>
            </Typography>
          </Box>
          <FitnessStateProvider>
            <FitnessPanel />
          </FitnessStateProvider>
        </>
      )}
    </MobileLayout>
  );
};

const FitnessPanel: React.FC = () => {
  const { status } = useFitnessState();
  switch (status) {
    case FitnessStatusConfig.Inactive:
      return <FitnessInactivePanel />;
    case FitnessStatusConfig.Registering:
      return <FitnessRegisteringPanel />;
    case FitnessStatusConfig.Pending:
      return <FitnessPendingPanel />;
    case FitnessStatusConfig.Active:
      return <FitnessActivePanel />;
    case FitnessStatusConfig.Expiring:
      return <FitnessExpiringPanel />;
    case FitnessStatusConfig.TooLate:
      return <FitnessTooLatePanel />;
    default:
      return <FitnessInactivePanel />;
  }
};
