/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FitnessBenefitDetailsDTO
 */
export interface FitnessBenefitDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'benefit': FitnessBenefitDetailsDTOBenefitEnum;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'status': FitnessBenefitDetailsDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'overrideId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof FitnessBenefitDetailsDTO
     */
    'monthlyBudget': number;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'registrationLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'deadlineDate': string;
    /**
     * 
     * @type {string}
     * @memberof FitnessBenefitDetailsDTO
     */
    'nextStartDate': string;
}

export const FitnessBenefitDetailsDTOBenefitEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Fitness: 'FITNESS',
    Custom: 'CUSTOM'
} as const;

export type FitnessBenefitDetailsDTOBenefitEnum = typeof FitnessBenefitDetailsDTOBenefitEnum[keyof typeof FitnessBenefitDetailsDTOBenefitEnum];
export const FitnessBenefitDetailsDTOStatusEnum = {
    Inactive: 'INACTIVE',
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Expiring: 'EXPIRING',
    TooLate: 'TOO_LATE'
} as const;

export type FitnessBenefitDetailsDTOStatusEnum = typeof FitnessBenefitDetailsDTOStatusEnum[keyof typeof FitnessBenefitDetailsDTOStatusEnum];


