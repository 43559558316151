import { Paper, Stack } from '@mui/material';
import FitnessFooter from '../FitnessFooter';
import FitnessHeader from '../FitnessHeader';
interface Props {
  title?: string;
  children: React.ReactNode;
}
export const FitnessPanel: React.FC<Props> = ({ title, children }) => {
  return (
    <Stack mt={1} p={1}>
      <Paper sx={{ p: 1 }}>
        {title && <FitnessHeader title={title} />}
        {children}
        <FitnessFooter />
      </Paper>
    </Stack>
  );
};

export default FitnessPanel;
