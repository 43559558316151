import { Box, Divider, TextField, Typography, useTheme } from '@mui/material';

import { BenefitDTOBenefitEnum, LunchReceiptOCRAnalysisDTO, LunchReceiptRefundDTOReceiptTypeEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button/LoadingButton';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicAlert } from '../../../component/alert';
import ReceiptConditions from '../components/ReceiptConditions';
import { ReceiptInfoCard } from '../../benefit/receipt/ReceiptInfoCard';
import { LunchReceiptTypes } from './LunchReceiptTypes';
import { ReceiptStep1Value } from './ReceiptFormStep1';
import { ReceiptStep2Value } from './ReceiptFormStep2';

const MAX_TOTAL = 6000;

export interface ReceiptStep3Value {
  lunchReceiptType?: LunchReceiptRefundDTOReceiptTypeEnum;
  comment?: string;
  userDidChangeComment: boolean;
}

interface Props {
  loading?: boolean;
  previousValues: ReceiptStep1Value & ReceiptStep2Value;
  ocrAnalysis?: LunchReceiptOCRAnalysisDTO;
  value?: ReceiptStep3Value;
  onSubmit: (value: ReceiptStep3Value) => void;
  onChange: (value: ReceiptStep3Value) => void;
}

export const ReceiptFormStep3: React.FC<Props> = ({ loading, previousValues, ocrAnalysis, value, onSubmit, onChange }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();

  const [isChecked, setIsChecked] = useState(false);
  const [comment, setComment] = useState(value?.comment);
  const [userDidChangeComment, setUserDidChangeComment] = useState(false);
  const [lunchReceiptType, setLunchReceiptType] = useState(value?.lunchReceiptType || LunchReceiptRefundDTOReceiptTypeEnum.Single);
  const isButtonDisabled =
    !isChecked ||
    (lunchReceiptType !== LunchReceiptRefundDTOReceiptTypeEnum.Single && !comment) ||
    !lunchReceiptType ||
    previousValues.total > MAX_TOTAL;

  const handleChangeComment = useCallback<React.ChangeEventHandler<HTMLInputElement>>(ev => {
    setUserDidChangeComment(true);
    setComment(ev.target.value);
  }, []);

  const handleCheckboxChange = useCallback((checked: boolean) => {
    setIsChecked(checked);
  }, []);

  useEffect(() => {
    onChange({ comment, userDidChangeComment, lunchReceiptType });
  }, [comment, onChange, lunchReceiptType, userDidChangeComment]);

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    ev => {
      ev.preventDefault();
      if (value) {
        onSubmit(value);
      }
    },
    [onSubmit, value],
  );

  return (
    <Box component="form" sx={{ px: 1, pt: 1, pb: 1.8, flexGrow: 1, display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        {previousValues.total > MAX_TOTAL && (
          <BasicAlert severity="warning" sx={{ marginBottom: 1 }}>
            {t(`benefit.LUNCH.warningTotal`)}
          </BasicAlert>
        )}
        <ReceiptInfoCard
          receipt={previousValues}
          sx={{ border: `1px solid ${theme.palette.datePicker.border}` }}
          benefit={BenefitDTOBenefitEnum.Lunch}
        />
        <Divider sx={{ marginBottom: 1 }} />
        <Typography variant="h6" color="text.secondary">
          {t(`benefit.LUNCH.field.comment`)}
          {lunchReceiptType === LunchReceiptRefundDTOReceiptTypeEnum.Mixed ? ' *' : ''}
        </Typography>
        <TextField
          sx={{ marginBottom: 1 }}
          slotProps={{
            htmlInput: {
              maxLength: 1000,
            },
          }}
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          placeholder={t(`benefit.LUNCH.placeholder.comment`)}
          disabled={!!previousValues.lineItems?.length || loading}
          value={comment}
          onChange={handleChangeComment}
        />
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
          {t('benefit.LUNCH.addReceiptFlow.receiptInfotext')}
        </Typography>
        <LunchReceiptTypes
          lunchReceiptType={lunchReceiptType}
          setLunchReceiptType={setLunchReceiptType}
          disabled={!!previousValues.lineItems?.length}
        />
        <ReceiptConditions benefit={BenefitDTOBenefitEnum.Lunch} value={isChecked} onChange={handleCheckboxChange} />
      </Box>
      <Box>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          loading={loading}
          disabled={isButtonDisabled}
          data-test-id="lunch-submit-receipt"
        >
          {t(`benefit.LUNCH.addReceiptFlow.submitReceipt`)}
        </LoadingButton>
      </Box>
    </Box>
  );
};
