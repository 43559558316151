import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { FITNESS_REGISTER_AMOUNT } from './useFitnessState';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useWithMessage } from 'probonio-shared-ui/module/error';

export function useFitnessMutation() {
  const getActiveEmployment = useActiveEmployment();
  const queryClient = useQueryClient();
  const withMessage = useWithMessage();
  const { t } = useTranslation('benefitModule');
  const { enqueueSnackbar } = useSnackbar();

  const activateOverride = useMutation({
    mutationFn: async () => {
      await apis.coupons.createCouponOverride({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
      });
    },

    onError: (err: Error) => {
      withMessage(err);
    },
    onSuccess: () => {
      localStorage.removeItem(FITNESS_REGISTER_AMOUNT);
      void queryClient.invalidateQueries({
        queryKey: [
          'tenants',
          getActiveEmployment().tenantId,
          'employees',
          getActiveEmployment().id,
          'benefits',
          'fitness',
          apis.benefits.getBenefit.name,
        ],
      });

      enqueueSnackbar(t('benefit.FITNESS.mutations.activate.success'), { variant: 'success' });
    },
  });
  const endOverride = useMutation({
    mutationFn: async (overrideId: string) => {
      await apis.coupons.endOverride({
        tenantId: getActiveEmployment().tenantId,
        employeeId: getActiveEmployment().id,
        overrideId,
      });
    },

    onError: (err: Error) => {
      withMessage(err);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [
          'tenants',
          getActiveEmployment().tenantId,
          'employees',
          getActiveEmployment().id,
          'benefits',
          'fitness',
          apis.benefits.getBenefit.name,
        ],
      });
      enqueueSnackbar(t('benefit.FITNESS.mutations.deactivate.success'), { variant: 'success' });
    },
  });

  return useMemo(
    () => ({
      activateOverride,
      endOverride,
    }),
    [activateOverride, endOverride],
  );
}
