import { Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { useActiveEmployment } from 'probonio-shared-ui/module/me';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FitnessActionButton } from '../FitnessActionButton';
import { FITNESS_REGISTER_AMOUNT, useFitnessState } from '../useFitnessState';
import FitnessPanel from './FitnessPanel';

export const FitnessInactivePanel: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const { monthlyBudget } = useFitnessState();

  const queryClient = useQueryClient();
  const getActiveEmployment = useActiveEmployment();
  const handleConfirm = useCallback(() => {
    if (monthlyBudget) {
      localStorage.setItem(FITNESS_REGISTER_AMOUNT, monthlyBudget.toString());
    }
    void queryClient.resetQueries({
      queryKey: [
        'tenants',
        getActiveEmployment().tenantId,
        'employees',
        getActiveEmployment().id,
        'benefits',
        'fitness',
        apis.benefits.getBenefit.name,
      ],
    });
  }, [getActiveEmployment, monthlyBudget, queryClient]);

  return (
    <>
      <FitnessActionButton onConfirm={handleConfirm} panelRenderer={FitnessRegistrationDrawerPanel} disabled={false} />
      <FitnessPanel title={t('benefitModule:benefit.FITNESS.INACTIVE.title')}>
        <Typography color="text.secondary" variant="body2">
          <Trans i18nKey="benefitModule:benefit.FITNESS.INACTIVE.info" />
        </Typography>
      </FitnessPanel>
    </>
  );
};

export interface FitnessRegistrationPanelProps {
  onConfirm: () => void;
}
const FitnessRegistrationDrawerPanel: React.FC<FitnessRegistrationPanelProps> = ({ onConfirm }) => {
  const { t } = useTranslation('benefitModule');
  const [isMailChecked, setIsMailChecked] = useState(false);
  const [isObligatoryChecked, setIsObligatoryChecked] = useState(false);
  const handleMailChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMailChecked(event.target.checked);
  }, []);
  const handleObligatoryChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsObligatoryChecked(event.target.checked);
  }, []);
  return (
    <Stack p={1} spacing={1}>
      <Stack>
        <Typography variant="h3">
          {t('benefit.FITNESS.INACTIVE.drawer.title')}
          <Divider />
        </Typography>
        <Typography color="text.secondary" variant="body2">
          <Trans i18nKey="benefitModule:benefit.FITNESS.INACTIVE.drawer.info" />
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h3">
          {t('benefit.FITNESS.INACTIVE.drawer.titleChbx')}
          <Divider />
        </Typography>

        <FormControlLabel
          control={<Checkbox checked={isMailChecked} onChange={handleMailChecked} />}
          label={
            <Typography variant="body2" color="text.secondary">
              <Trans i18nKey="benefitModule:benefit.FITNESS.INACTIVE.drawer.mailChbx" />
            </Typography>
          }
        />

        <FormControlLabel
          control={<Checkbox checked={isObligatoryChecked} onChange={handleObligatoryChecked} />}
          label={
            <Typography variant="body2" color="text.secondary">
              {t('benefit.FITNESS.INACTIVE.drawer.obligatoryChbx')}
            </Typography>
          }
        />
      </Stack>
      <LoadingButton size="small" type="submit" variant="contained" disabled={!(isMailChecked && isObligatoryChecked)} onClick={onConfirm}>
        <Typography>{t('benefit.FITNESS.INACTIVE.drawer.activate')}</Typography>
      </LoadingButton>
    </Stack>
  );
};
