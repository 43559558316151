/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FitnessTransactionsHistoryDTO } from '../dto';
/**
 * FitnessApi - axios parameter creator
 * @export
 */
export const FitnessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exports Fitness Members with according Acctions in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCSV: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/benefits/fitness/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionHistory: async (tenantId: string, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTransactionHistory', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/fitness/transactionHistory`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (withAggregations !== undefined) {
                localVarQueryParameter['withAggregations'] = withAggregations;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FitnessApi - functional programming interface
 * @export
 */
export const FitnessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FitnessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exports Fitness Members with according Acctions in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCSV(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCSV(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionHistory(tenantId: string, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitnessTransactionsHistoryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionHistory(tenantId, filter, withAggregations, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FitnessApi - factory interface
 * @export
 */
export const FitnessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FitnessApiFp(configuration)
    return {
        /**
         * 
         * @summary Exports Fitness Members with according Acctions in CSV format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCSV(options?: any): AxiosPromise<string> {
            return localVarFp.getCSV(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [filter] 
         * @param {boolean} [withAggregations] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionHistory(tenantId: string, filter?: string, withAggregations?: boolean, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<FitnessTransactionsHistoryDTO> {
            return localVarFp.getTransactionHistory(tenantId, filter, withAggregations, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTransactionHistory operation in FitnessApi.
 * @export
 * @interface FitnessApiGetTransactionHistoryRequest
 */
export interface FitnessApiGetTransactionHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof FitnessApiGetTransactionHistory
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof FitnessApiGetTransactionHistory
     */
    readonly filter?: string

    /**
     * 
     * @type {boolean}
     * @memberof FitnessApiGetTransactionHistory
     */
    readonly withAggregations?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof FitnessApiGetTransactionHistory
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof FitnessApiGetTransactionHistory
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof FitnessApiGetTransactionHistory
     */
    readonly pageSize?: number
}

/**
 * FitnessApi - object-oriented interface
 * @export
 * @class FitnessApi
 * @extends {BaseAPI}
 */
export class FitnessApi extends BaseAPI {
    /**
     * 
     * @summary Exports Fitness Members with according Acctions in CSV format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitnessApi
     */
    public getCSV(options?: AxiosRequestConfig) {
        return FitnessApiFp(this.configuration).getCSV(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FitnessApiGetTransactionHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitnessApi
     */
    public getTransactionHistory(requestParameters: FitnessApiGetTransactionHistoryRequest, options?: AxiosRequestConfig) {
        return FitnessApiFp(this.configuration).getTransactionHistory(requestParameters.tenantId, requestParameters.filter, requestParameters.withAggregations, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
