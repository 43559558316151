import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../component/confirmationModal';
import { DialogState, useNewDialogState } from '../../../component/dialog/BasicDialog';
import { useAppSelector } from '../../../redux/hooks';
import { FitnessActionButton } from '../FitnessActionButton';
import { useFitnessMutation } from '../useFitnessMutation';
import { useFitnessState } from '../useFitnessState';
import FitnessPanel from './FitnessPanel';

export const FitnessRegisteringPanel: React.FC = () => {
  const { t } = useTranslation('benefitModule');
  const mail = useAppSelector(state => state.me.user?.mail);
  const { activateOverride } = useFitnessMutation();
  const registeredConfirmation = useNewDialogState();
  const { registrationLink } = useFitnessState();

  const handleConfirmationSubmit = useCallback(() => {
    activateOverride.mutate();
  }, [activateOverride]);

  const handleRegistered = useCallback(() => {
    registeredConfirmation.handleOpen();
  }, [registeredConfirmation]);

  return (
    <>
      <FitnessActionButton disabled={true} />
      <FitnessPanel title={t('benefitModule:benefit.FITNESS.REGISTERING.title')}>
        <Typography color="text.secondary" variant="body2">
          <Trans i18nKey="benefitModule:benefit.FITNESS.REGISTERING.info" values={{ mail }} />
        </Typography>
      </FitnessPanel>

      <Stack p={1} spacing={1}>
        <Button variant="outlined" fullWidth href={registrationLink} target="_blank">
          {t('benefitModule:benefit.FITNESS.REGISTERING.actionLink')}
        </Button>
        <LoadingButton variant="contained" color="primary" fullWidth onClick={handleRegistered}>
          {t('benefitModule:benefit.FITNESS.REGISTERING.actionRegistered')}
        </LoadingButton>
      </Stack>
      <FitnessRegisteringConfirmationModal dialogState={registeredConfirmation.dialogState} onSubmit={handleConfirmationSubmit} />
    </>
  );
};

interface Props {
  dialogState: DialogState;
  onSubmit: () => void;
}

export const FitnessRegisteringConfirmationModal: React.FC<Props> = ({ dialogState, onSubmit }) => {
  const { t } = useTranslation('benefitModule');

  return (
    <ConfirmationModal
      onCancel={dialogState.handleClose}
      onSubmit={onSubmit}
      cancelButtonTitle={t(`benefitModule:benefit.FITNESS.REGISTERING.confirmation.cancel`)}
      okButtonTitle={t(`benefitModule:benefit.FITNESS.REGISTERING.confirmation.ok`)}
      title={t(`benefitModule:benefit.FITNESS.REGISTERING.confirmation.title`)}
      message={t(`benefitModule:benefit.FITNESS.REGISTERING.confirmation.message`)}
      isOpen={dialogState.isOpen}
    />
  );
};

export default FitnessRegisteringPanel;
