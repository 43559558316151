/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ReceiptOCRLineItemDTO } from './receipt-ocrline-item-dto';

/**
 * 
 * @export
 * @interface LunchReceiptOCRAnalysisDTO
 */
export interface LunchReceiptOCRAnalysisDTO {
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'analysisResult': LunchReceiptOCRAnalysisDTOAnalysisResultEnum;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'countryCode'?: LunchReceiptOCRAnalysisDTOCountryCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'errorReason'?: LunchReceiptOCRAnalysisDTOErrorReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'ocrResultId'?: string;
    /**
     * 
     * @type {number}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'ocrTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'isLineItemsTotalValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'hasFoodLineItems'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'ocrPostalCode'?: string;
    /**
     * 
     * @type {Array<ReceiptOCRLineItemDTO>}
     * @memberof LunchReceiptOCRAnalysisDTO
     */
    'lineItems': Array<ReceiptOCRLineItemDTO>;
}

export const LunchReceiptOCRAnalysisDTOAnalysisResultEnum = {
    Success: 'SUCCESS',
    NoReceipt: 'NO_RECEIPT',
    Error: 'ERROR'
} as const;

export type LunchReceiptOCRAnalysisDTOAnalysisResultEnum = typeof LunchReceiptOCRAnalysisDTOAnalysisResultEnum[keyof typeof LunchReceiptOCRAnalysisDTOAnalysisResultEnum];
export const LunchReceiptOCRAnalysisDTOCountryCodeEnum = {
    De: 'DE',
    At: 'AT',
    Ch: 'CH',
    UnknownCountry: 'UNKNOWN_COUNTRY'
} as const;

export type LunchReceiptOCRAnalysisDTOCountryCodeEnum = typeof LunchReceiptOCRAnalysisDTOCountryCodeEnum[keyof typeof LunchReceiptOCRAnalysisDTOCountryCodeEnum];
export const LunchReceiptOCRAnalysisDTOErrorReasonEnum = {
    Date: 'NO_DATE',
    Total: 'NO_TOTAL'
} as const;

export type LunchReceiptOCRAnalysisDTOErrorReasonEnum = typeof LunchReceiptOCRAnalysisDTOErrorReasonEnum[keyof typeof LunchReceiptOCRAnalysisDTOErrorReasonEnum];


