import { Box } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';

export interface Stringer {
  toString(): string;
}

interface ScrollToBoxProps<T extends Stringer> {
  activeValue?: T;
  value: T;
  onClick: (value: T) => void;
  children: React.ReactNode;
}

export function ScrollToBox<T extends Stringer>({ activeValue, value, onClick, children }: ScrollToBoxProps<T>): React.ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeValue?.toString() === value.toString() && ref.current) {
      const element = ref.current;
      const scrollParent = element?.offsetParent! as HTMLElement;
      const x = element.offsetLeft + element.offsetWidth / 2 - scrollParent!.offsetWidth / 2;
      scrollParent!.scrollTo({ left: x, behavior: 'smooth' });
    }
  }, [activeValue, value]);

  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Box
      data-test-id={`time-card-${value}`}
      ref={ref}
      sx={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      {children}
    </Box>
  );
}

export interface ScrollPickerOption<T extends Stringer> {
  value: T;
  component: React.ReactNode;
}

interface ScrollPickerProps<T extends Stringer> {
  options: ScrollPickerOption<T>[];
  value?: T;
  onChange: (month: T) => void;
}

export function GenericScrollPicker<T extends Stringer>({ options, value, onChange }: ScrollPickerProps<T>): React.ReactElement {
  const dateBoxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = dateBoxContainerRef.current;
    if (container) {
      const scrollEvent = (e: HTMLElementEventMap['wheel']): void => {
        container.scrollLeft += e.deltaY;
      };
      dateBoxContainerRef.current?.addEventListener('wheel', scrollEvent);
      return () => {
        container.removeEventListener('wheel', scrollEvent);
      };
    }
  }, []);

  return (
    <Box sx={{ maskImage: 'linear-gradient(to left, rgba(0,0,0,0.25), rgba(0,0,0,1), rgba(0,0,0,0.25))' }}>
      <Box
        ref={dateBoxContainerRef}
        sx={{
          overflowX: 'auto',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
          }}
        >
          <Box sx={{ width: 'min(40vw, 180px)', flexShrink: 0 }} />
          {options?.map(option => (
            <ScrollToBox key={option.value.toString()} activeValue={value} value={option.value} onClick={onChange}>
              {option.component}
            </ScrollToBox>
          ))}
          <Box sx={{ width: 'min(40vw, 180px)', flexShrink: 0 }} />
        </Box>
      </Box>
    </Box>
  );
}
