import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { FitnessActionButton } from '../FitnessActionButton';

export const FitnessTooLatePanel: React.FC = () => {
  return (
    <>
      <FitnessActionButton disabled={true} />
      <Stack mt={1} p={1}>
        <Paper sx={{ p: 1 }}>
          <Stack alignItems={'center'} spacing={1} p={1}>
            <Typography variant="h3" textAlign={'center'}>
              <Trans i18nKey="benefitModule:benefit.FITNESS.TOO_LATE.info" />
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default FitnessTooLatePanel;
