import { Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FitnessActionButton } from '../FitnessActionButton';
import { useFitnessMutation } from '../useFitnessMutation';
import { useFitnessState } from '../useFitnessState';
import FitnessPanel from './FitnessPanel';

export const FitnessActivePanel = () => {
  const { t } = useTranslation('benefitModule');
  const { endOverride } = useFitnessMutation();
  const { overrideId } = useFitnessState();
  const handleConfirm = useCallback(() => {
    if (overrideId) {
      endOverride.mutate(overrideId);
    }
  }, [endOverride, overrideId]);

  return (
    <>
      <FitnessActionButton onConfirm={handleConfirm} panelRenderer={FitnessUnregisterDrawerPanel} disabled={false} />
      <FitnessPanel title={t('benefitModule:benefit.FITNESS.ACTIVE.title')}>
        <Typography color="text.secondary" variant="body2" mb={1}>
          <Trans i18nKey="benefitModule:benefit.FITNESS.ACTIVE.info" />
        </Typography>
      </FitnessPanel>
    </>
  );
};

export default FitnessActivePanel;

export interface FitnessUnregisterDrawerPanelProps {
  onConfirm: () => void;
}
const FitnessUnregisterDrawerPanel: React.FC<FitnessUnregisterDrawerPanelProps> = ({ onConfirm }) => {
  const { t } = useTranslation('benefitModule');
  const [isProbonioChecked, setIsProbonioChecked] = useState(false);
  const [isObligatoryChecked, setIsObligatoryChecked] = useState(false);
  const handleProbonioChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsProbonioChecked(event.target.checked);
  }, []);
  const handleObligatoryChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsObligatoryChecked(event.target.checked);
  }, []);
  return (
    <Stack p={1} spacing={1}>
      <Stack>
        <Typography variant="h3">
          {t('benefit.FITNESS.ACTIVE.drawer.title')}
          <Divider />
        </Typography>
        <Typography color="text.secondary" variant="body2">
          <Trans i18nKey="benefitModule:benefit.FITNESS.ACTIVE.info" />
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h3">
          {t('benefit.FITNESS.ACTIVE.drawer.titleChbx')}
          <Divider />
        </Typography>

        <FormControlLabel
          control={<Checkbox checked={isObligatoryChecked} onChange={handleObligatoryChecked} />}
          label={
            <Typography variant="body2" color="text.secondary">
              <Trans i18nKey="benefitModule:benefit.FITNESS.ACTIVE.drawer.obligatoryChbx" />
            </Typography>
          }
        />

        <FormControlLabel
          control={<Checkbox checked={isProbonioChecked} onChange={handleProbonioChecked} />}
          label={
            <Typography variant="body2" color="text.secondary">
              {t('benefit.FITNESS.ACTIVE.drawer.probonioChbx')}
            </Typography>
          }
        />
      </Stack>
      <LoadingButton
        size="small"
        type="submit"
        variant="contained"
        disabled={!(isProbonioChecked && isObligatoryChecked)}
        onClick={onConfirm}
      >
        <Typography>{t('benefit.FITNESS.ACTIVE.drawer.deactivate')}</Typography>
      </LoadingButton>
    </Stack>
  );
};
