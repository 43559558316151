import KeyboardIcon from '@mui/icons-material/Keyboard';
import { Typography } from '@mui/material';
import { CreateLunchReceiptLineItemDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencyInput, Operator } from '../../../component/currencyInput';
import { Drawable } from '../../../component/drawable/Drawable';
import { ChecklistItem } from '../components/ChecklistItem';
import { FloatingInfoButton } from '../components/FloatingInfoButton';

export interface ReceiptStep1Value {
  total: number;
  comment?: string;
  valuesArray: number[];
  operatorArray: Operator[];
  lineItems?: CreateLunchReceiptLineItemDTO[];
}

interface Props {
  value?: ReceiptStep1Value;
  onChange: (value: ReceiptStep1Value) => void;
}

function getLastOrZero(values?: number[]): number {
  if (!values || values.length === 0) {
    return 0;
  }

  return values[values.length - 1];
}

export const ReceiptFormStep1: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation('benefitModule');
  const [currentInput, setCurrentInput] = useState<number>(getLastOrZero(value?.valuesArray));
  const [calculatorString, setCalculatorString] = useState('');
  const [calculatorResult, setCalculatorResult] = useState(value?.total || 0);
  const [currentValuesArray, setCurrentValuesArray] = useState(value?.valuesArray || []);
  const [currentOperatorArray, setCurrentOperatorArray] = useState(value?.operatorArray || []);

  useEffect(() => {
    setCurrentInput(getLastOrZero(value?.valuesArray));
    setCalculatorString('');
    setCalculatorResult(value?.total || 0);
    setCurrentValuesArray(value?.valuesArray || []);
    setCurrentOperatorArray(value?.operatorArray || []);
  }, [value]);

  const handleSubmit = useCallback(() => {
    onChange({
      total: calculatorResult,
      comment: calculatorString,
      valuesArray: currentValuesArray,
      operatorArray: currentOperatorArray,
      lineItems: value?.lineItems,
    });
  }, [onChange, calculatorResult, calculatorString, currentValuesArray, currentOperatorArray, value?.lineItems]);

  return (
    <>
      <FloatingInfoButton stateKey="lunch-step-1">
        <Trans i18nKey="benefitModule:benefit.LUNCH.receiptStep1Checklist">
          <ChecklistItem />
        </Trans>
      </FloatingInfoButton>
      <Drawable icon={<KeyboardIcon />}>
        {currentOperatorArray.length > 0 ? (
          <Typography variant="paragraph" paddingBottom={0.3}>
            {calculatorString}
          </Typography>
        ) : (
          <Typography variant="paragraph" color="primary" paddingBottom={0.3} sx={{ textAlign: 'center' }}>
            {t(`benefit.LUNCH.addReceiptFlow.hintTotal`)}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <CurrencyInput
            onSubmit={handleSubmit}
            currentInputValue={currentInput}
            calculatorResult={calculatorResult}
            maxNumber={99999}
            hideCalculator={false}
            setCurrentInputValue={setCurrentInput}
            onCalculatorStringChange={setCalculatorString}
            onCalculatorResultChange={setCalculatorResult}
            onValuesArrayChange={setCurrentValuesArray}
            onOperatorArrayChange={setCurrentOperatorArray}
            valuesArray={currentValuesArray}
            operatorArray={currentOperatorArray}
          />
        </form>
      </Drawable>
    </>
  );
};
