import { SentryRoutes } from 'probonio-shared-ui/module/error/sentry';
import { Route } from 'react-router-dom';
import { FitnessPage } from './FitnessPage';
import FitnessInfoPage from './FitnessInfoPage';

const FitnessRoutes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/info" element={<FitnessInfoPage />} />
      <Route path="/" element={<FitnessPage />} />
    </SentryRoutes>
  );
};

export default FitnessRoutes;
