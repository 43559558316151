import { Divider, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
}

const FitnessHeader: React.FC<Props> = ({ title }: Props) => {
  return (
    <Stack>
      <Typography variant="h3">{title}</Typography>
      <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
    </Stack>
  );
};

export default FitnessHeader;
