import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import axios from 'axios';
import React, { FC, useEffect, useMemo } from 'react';
import {
  BAVApi,
  BenefitsApi,
  BikeApi,
  BikeleasingApi,
  Configuration,
  CouponsApi,
  CouponsV2Api,
  CustomBenefitApi,
  DocumentsApi,
  EmployeeApi,
  FeedbackApi,
  HRSystemApi,
  InternetApi,
  LegalTermsApi,
  LunchApi,
  MeApi,
  MobilityApi,
  NewsApi,
  PublicApi,
  ReceiptAuditApi,
  RecreationApi,
  SalaryCalculatorApi,
  SepaApi,
  TenantApi,
  UserApi,
  FitnessApi,
} from '../../api';

export interface APIs {
  me: MeApi;
  user: UserApi;
  benefits: BenefitsApi;
  employee: EmployeeApi;
  tenants: TenantApi;
  lunch: LunchApi;
  mobility: MobilityApi;
  internet: InternetApi;
  news: NewsApi;
  recreation: RecreationApi;
  salaryCalculator: SalaryCalculatorApi;
  coupons: CouponsApi;
  couponsV2: CouponsV2Api;
  customBenefits: CustomBenefitApi;
  receiptAudit: ReceiptAuditApi;
  feedback: FeedbackApi;
  sepa: SepaApi;
  hrSystem: HRSystemApi;
  documents: DocumentsApi;
  bav: BAVApi;
  bike: BikeApi;
  legalTerms: LegalTermsApi;
  bikeLeasing: BikeleasingApi;
  fitness: FitnessApi;
  public: PublicApi;

  /**
   * Injects a new config for the apis.
   */
  setAPIConfig: (conf: Configuration) => void;
}

const initialConfig = new Configuration({
  basePath: (import.meta.env.VITE_APP_API_URL || '').toString(),
});

export const apis: APIs = {
  me: new MeApi(initialConfig),
  user: new UserApi(initialConfig),
  employee: new EmployeeApi(initialConfig),
  tenants: new TenantApi(initialConfig),
  benefits: new BenefitsApi(initialConfig),
  lunch: new LunchApi(initialConfig),
  mobility: new MobilityApi(initialConfig),
  internet: new InternetApi(initialConfig),
  news: new NewsApi(initialConfig),
  recreation: new RecreationApi(initialConfig),
  salaryCalculator: new SalaryCalculatorApi(initialConfig),
  coupons: new CouponsApi(initialConfig),
  couponsV2: new CouponsV2Api(initialConfig),
  customBenefits: new CustomBenefitApi(initialConfig),
  receiptAudit: new ReceiptAuditApi(initialConfig),
  feedback: new FeedbackApi(initialConfig),
  sepa: new SepaApi(initialConfig),
  hrSystem: new HRSystemApi(initialConfig),
  documents: new DocumentsApi(initialConfig),
  bav: new BAVApi(initialConfig),
  bike: new BikeApi(initialConfig),
  legalTerms: new LegalTermsApi(initialConfig),
  bikeLeasing: new BikeleasingApi(initialConfig),
  fitness: new FitnessApi(initialConfig),
  public: new PublicApi(initialConfig),
  setAPIConfig: config => {
    apis.me = new MeApi(config);
    apis.user = new UserApi(config);
    apis.employee = new EmployeeApi(config);
    apis.tenants = new TenantApi(config);
    apis.benefits = new BenefitsApi(config);
    apis.lunch = new LunchApi(config);
    apis.mobility = new MobilityApi(config);
    apis.coupons = new CouponsApi(config);
    apis.couponsV2 = new CouponsV2Api(config);
    apis.internet = new InternetApi(config);
    apis.news = new NewsApi(config);
    apis.recreation = new RecreationApi(config);
    apis.salaryCalculator = new SalaryCalculatorApi(config);
    apis.customBenefits = new CustomBenefitApi(config);
    apis.receiptAudit = new ReceiptAuditApi(config);
    apis.feedback = new FeedbackApi(config);
    apis.sepa = new SepaApi(config);
    apis.hrSystem = new HRSystemApi(config);
    apis.documents = new DocumentsApi(config);
    apis.bav = new BAVApi(config);
    apis.bike = new BikeApi(config);
    apis.legalTerms = new LegalTermsApi(config);
    apis.bikeLeasing = new BikeleasingApi(config);
    apis.public = new PublicApi(config);
    apis.fitness = new FitnessApi(config);
  },
};

interface Props {
  children: React.ReactNode;
  onLoginStateChange?: (hasToken: boolean) => void;
}
const mutationCache = new MutationCache();
const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const APILoader: FC<Props> = ({ onLoginStateChange, children }) => {
  const { token, checkToken } = useLoginContext();

  // Instead of a useEffect, pass the token to the global API instance in a useMemo call
  // that runs synchronously in the render phase. This avoids race conditions with child components
  // listening for changes to isLoggedIn
  useMemo(() => {
    apis.setAPIConfig(
      new Configuration({
        basePath: (import.meta.env.VITE_APP_API_URL || '').toString(),
        baseOptions: token
          ? {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          : undefined,
      }),
    );
  }, [token]);

  useEffect(() => {
    onLoginStateChange?.(!!token);
  }, [onLoginStateChange, token]);

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(async config => {
      const result = await checkToken();
      if (result) {
        config.headers.setAuthorization('Bearer ' + result.token);
      }
      return config;
    });
    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [checkToken]);

  return (
    <QueryClientProvider client={queryClient}>
      {children} <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
