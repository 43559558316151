import { Box, Typography, Paper, Divider, useTheme } from '@mui/material';

import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

const FitnessText: React.FC<{ descriptionKey: string; titleKey: string }> = ({ titleKey, descriptionKey }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{t(titleKey)}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
          <Typography variant="body2">
            <Trans i18nKey={`benefitModule:${descriptionKey}`}>
              <a href="https://www.urbansportsclub.com/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}></a>
            </Trans>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const FitnessInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout
      header={<AppToolbar title={t('benefit.FITNESS.infoPage.title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={'auto'}
        justifyContent="space-between"
        alignItems="center"
        marginX={1}
        gap={0.8}
        marginBottom={0.7}
      >
        <Box
          borderRadius={'8px'}
          component="img"
          width={'100%'}
          sx={{
            objectFit: 'cover',
            maxWidth: 410,
            maxHeight: 150,
          }}
          src="/image/urban-sports-club.jpg"
        />
        <FitnessText descriptionKey={'benefit.FITNESS.infoPage.description.text'} titleKey={'benefit.FITNESS.infoPage.description.title'} />
        <FitnessText descriptionKey={'benefit.FITNESS.infoPage.facts.text'} titleKey={'benefit.FITNESS.infoPage.facts.title'} />
        <FitnessText
          titleKey={'benefit.FITNESS.infoPage.instructions.title'}
          descriptionKey={'benefit.FITNESS.infoPage.instructions.text'}
        />
        <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
      </Box>
    </MobileLayout>
  );
};
export default FitnessInfoPage;
