import CakeIcon from '@mui/icons-material/Cake';
import ChairIcon from '@mui/icons-material/Chair';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EuroIcon from '@mui/icons-material/Euro';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WifiIcon from '@mui/icons-material/Wifi';
import { SvgIconProps } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { BikeleasingBIcon, PalmTreeIcon, ThreeWayArrowIcon } from 'probonio-shared-ui/component/icon';

export const BENEFIT_ICONS: Record<BenefitDTOBenefitEnum, React.ComponentType<SvgIconProps>> = {
  BAV: ChairIcon,
  BIKE: DirectionsBikeIcon,
  LUNCH: FastfoodIcon,
  MOBILITY: DirectionsBusIcon,
  INTERNET: WifiIcon,
  COUPONS: LocalOfferIcon,
  COUPONS_V2: LocalOfferIcon,
  GIFTS: CakeIcon,
  NEWS: TextSnippetIcon,
  FLEX: ThreeWayArrowIcon,
  RECREATION: PalmTreeIcon,
  CUSTOM: EuroIcon,
  BIKELEASING: BikeleasingBIcon,
  FITNESS: FitnessCenterIcon,
};
