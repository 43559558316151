import { Box, Divider, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FitnessBenefitDetailsDTOStatusEnum } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';
import { useFitnessState } from './useFitnessState';

const FitnessFooter: React.FC = () => {
  const { status, nextStartDate, endDate, deadlineDate, startDate } = useFitnessState();
  const { t } = useTranslation('benefitModule');

  return (
    <Stack spacing={1}>
      <Divider sx={{ my: 1 }} />
      <Box>
        {status === FitnessBenefitDetailsDTOStatusEnum.Expiring && endDate && (
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {t(`benefit.FITNESS.period.${status}.at`)}
            <Typography fontWeight="bold" textAlign="center" variant="body2" component="span">
              {t(`benefit.FITNESS.period.month`, { month: DateTime.fromJSDate(endDate).plus({ month: 1 }).toJSDate() })}
            </Typography>
          </Typography>
        )}
        {status === FitnessBenefitDetailsDTOStatusEnum.Pending && startDate && (
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {t(`benefit.FITNESS.period.${status}.at`)}
            <Typography fontWeight="bold" textAlign="center" variant="body2" component="span">
              {t(`benefit.FITNESS.period.month`, { month: startDate })}
            </Typography>
          </Typography>
        )}
        {(status === FitnessBenefitDetailsDTOStatusEnum.Active || status === FitnessBenefitDetailsDTOStatusEnum.Inactive) && (
          <>
            <Typography color="text.secondary" variant="body2" textAlign="center">
              {t(`benefit.FITNESS.period.${status}.nextMonth`)}
              <Typography fontWeight="bold" variant="body2" textAlign="center" component="span">
                {t(`benefit.FITNESS.period.month`, { month: nextStartDate })}
              </Typography>
            </Typography>
            <Typography color="text.secondary" variant="body2" textAlign="center">
              {t(`benefit.FITNESS.period.${status}.deadline`)}
              <Typography fontWeight="bold" textAlign="center" variant="body2" component="span">
                {t(`benefit.FITNESS.period.deadline`, { deadline: deadlineDate })}
              </Typography>
            </Typography>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default FitnessFooter;
