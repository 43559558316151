import { useTheme, Drawer } from '@mui/material';
import { useState, useCallback } from 'react';
import { CircularFloatingButton } from '../../component/button';
import { FitnessRegistrationPanelProps } from './panels/FitnessInactivePanel';
import EditIcon from '@mui/icons-material/Edit';

type FitnessActionButtonProps =
  | {
      disabled: true;
    }
  | {
      disabled: false;
      onConfirm: () => void;
      panelRenderer: React.ComponentType<FitnessRegistrationPanelProps>;
    };
export const FitnessActionButton: React.FC<FitnessActionButtonProps> = props => {
  const { disabled } = props;
  const Panel = !disabled ? props.panelRenderer : null;
  const onConfirm = !disabled ? props.onConfirm : null;

  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);
  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  return (
    <>
      <CircularFloatingButton borderColor={theme.palette.primary.main} buttonProps={{ onClick: handleOpenDrawer, disabled }}>
        <EditIcon fontSize="large" />
      </CircularFloatingButton>
      <Drawer anchor="bottom" open={drawerOpen} onClose={handleCloseDrawer} PaperProps={{ sx: { maxWidth: 455, margin: 'auto' } }}>
        {Panel && <Panel onConfirm={handleConfirm} />}
      </Drawer>
    </>
  );
};
