/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyBenefitTemplateDTO } from '../dto';
// @ts-ignore
import { BenefitActivationDTO } from '../dto';
// @ts-ignore
import { BenefitActivationListDTO } from '../dto';
// @ts-ignore
import { BenefitDTO } from '../dto';
// @ts-ignore
import { BenefitExpensesDTO } from '../dto';
// @ts-ignore
import { BenefitListDTO } from '../dto';
// @ts-ignore
import { BenefitTemplateDTO } from '../dto';
// @ts-ignore
import { BenefitTemplateListDTO } from '../dto';
// @ts-ignore
import { BenefitUpsellingListDTO } from '../dto';
// @ts-ignore
import { BulkApplyBenefitTemplateDTO } from '../dto';
// @ts-ignore
import { BulkCreateBenefitActivationsDTO } from '../dto';
// @ts-ignore
import { BulkCreateResponseDTO } from '../dto';
// @ts-ignore
import { BulkUpdateBenefitActivationsDTO } from '../dto';
// @ts-ignore
import { CreateBenefitActivationDTO } from '../dto';
// @ts-ignore
import { CreateBenefitTemplateDTO } from '../dto';
// @ts-ignore
import { CreateTenantBenefitSortingListDTO } from '../dto';
// @ts-ignore
import { FinancialReportDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { ResendPayrollReportRequestDTO } from '../dto';
// @ts-ignore
import { TenantBenefitSortingListDTO } from '../dto';
// @ts-ignore
import { UpdateBenefitActivationDTO } from '../dto';
// @ts-ignore
import { UpdateBenefitTemplateDTO } from '../dto';
/**
 * BenefitsApi - axios parameter creator
 * @export
 */
export const BenefitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary apply all benefits of template to employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {ApplyBenefitTemplateDTO} applyBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBenefitTemplate: async (tenantId: string, employeeId: string, applyBenefitTemplateDTO: ApplyBenefitTemplateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('applyBenefitTemplate', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('applyBenefitTemplate', 'employeeId', employeeId)
            // verify required parameter 'applyBenefitTemplateDTO' is not null or undefined
            assertParamExists('applyBenefitTemplate', 'applyBenefitTemplateDTO', applyBenefitTemplateDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefitActivations/applyTemplate`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyBenefitTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary apply all benefits of template to selected employees
         * @param {string} tenantId 
         * @param {BulkApplyBenefitTemplateDTO} bulkApplyBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkApplyBenefitTemplate: async (tenantId: string, bulkApplyBenefitTemplateDTO: BulkApplyBenefitTemplateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('bulkApplyBenefitTemplate', 'tenantId', tenantId)
            // verify required parameter 'bulkApplyBenefitTemplateDTO' is not null or undefined
            assertParamExists('bulkApplyBenefitTemplate', 'bulkApplyBenefitTemplateDTO', bulkApplyBenefitTemplateDTO)
            const localVarPath = `/tenants/{tenantId}/benefitActivations/applyTemplate`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkApplyBenefitTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBenefitActivationDTO} createBenefitActivationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitActivation: async (tenantId: string, employeeId: string, createBenefitActivationDTO: CreateBenefitActivationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createBenefitActivation', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createBenefitActivation', 'employeeId', employeeId)
            // verify required parameter 'createBenefitActivationDTO' is not null or undefined
            assertParamExists('createBenefitActivation', 'createBenefitActivationDTO', createBenefitActivationDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefitActivations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBenefitActivationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates new benefit activations for the given employee
         * @param {string} tenantId 
         * @param {BulkCreateBenefitActivationsDTO} bulkCreateBenefitActivationsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitActivations: async (tenantId: string, bulkCreateBenefitActivationsDTO: BulkCreateBenefitActivationsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createBenefitActivations', 'tenantId', tenantId)
            // verify required parameter 'bulkCreateBenefitActivationsDTO' is not null or undefined
            assertParamExists('createBenefitActivations', 'bulkCreateBenefitActivationsDTO', bulkCreateBenefitActivationsDTO)
            const localVarPath = `/tenants/{tenantId}/benefitActivations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkCreateBenefitActivationsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a new benefit sorting for the tenant
         * @param {string} tenantId 
         * @param {CreateTenantBenefitSortingListDTO} createTenantBenefitSortingListDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitSorting: async (tenantId: string, createTenantBenefitSortingListDTO: CreateTenantBenefitSortingListDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createBenefitSorting', 'tenantId', tenantId)
            // verify required parameter 'createTenantBenefitSortingListDTO' is not null or undefined
            assertParamExists('createBenefitSorting', 'createTenantBenefitSortingListDTO', createTenantBenefitSortingListDTO)
            const localVarPath = `/tenants/{tenantId}/benefitSorting`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantBenefitSortingListDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new benefit template for the tenant
         * @param {string} tenantId 
         * @param {CreateBenefitTemplateDTO} createBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitTemplate: async (tenantId: string, createBenefitTemplateDTO: CreateBenefitTemplateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createBenefitTemplate', 'tenantId', tenantId)
            // verify required parameter 'createBenefitTemplateDTO' is not null or undefined
            assertParamExists('createBenefitTemplate', 'createBenefitTemplateDTO', createBenefitTemplateDTO)
            const localVarPath = `/tenants/{tenantId}/benefitTemplates`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBenefitTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitActivation: async (tenantId: string, employeeId: string, benefitActivationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteBenefitActivation', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteBenefitActivation', 'employeeId', employeeId)
            // verify required parameter 'benefitActivationId' is not null or undefined
            assertParamExists('deleteBenefitActivation', 'benefitActivationId', benefitActivationId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefitActivations/{benefitActivationId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"benefitActivationId"}}`, encodeURIComponent(String(benefitActivationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a benefit template for the tenant
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitTemplate: async (tenantId: string, benefitTemplateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteBenefitTemplate', 'tenantId', tenantId)
            // verify required parameter 'benefitTemplateId' is not null or undefined
            assertParamExists('deleteBenefitTemplate', 'benefitTemplateId', benefitTemplateId)
            const localVarPath = `/tenants/{tenantId}/benefitTemplates/{benefitTemplateId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"benefitTemplateId"}}`, encodeURIComponent(String(benefitTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary read benefit by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} benefit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefit: async (tenantId: string, employeeId: string, benefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefit', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getBenefit', 'employeeId', employeeId)
            // verify required parameter 'benefit' is not null or undefined
            assertParamExists('getBenefit', 'benefit', benefit)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/{benefit}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"benefit"}}`, encodeURIComponent(String(benefit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary read benefit activation by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitActivation: async (tenantId: string, employeeId: string, benefitActivationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefitActivation', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getBenefitActivation', 'employeeId', employeeId)
            // verify required parameter 'benefitActivationId' is not null or undefined
            assertParamExists('getBenefitActivation', 'benefitActivationId', benefitActivationId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefitActivations/{benefitActivationId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"benefitActivationId"}}`, encodeURIComponent(String(benefitActivationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all benefit activations from employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [benefit] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [excludeBenefit] 
         * @param {boolean} [onlyActive] 
         * @param {string} [customBenefitId] 
         * @param {string} [excludeCustomBenefitId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitActivations: async (tenantId: string, employeeId: string, benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', startDate?: string, endDate?: string, excludeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', onlyActive?: boolean, customBenefitId?: string, excludeCustomBenefitId?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefitActivations', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getBenefitActivations', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefitActivations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (benefit !== undefined) {
                localVarQueryParameter['benefit'] = benefit;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (excludeBenefit !== undefined) {
                localVarQueryParameter['excludeBenefit'] = excludeBenefit;
            }

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }

            if (customBenefitId !== undefined) {
                localVarQueryParameter['customBenefitId'] = customBenefitId;
            }

            if (excludeCustomBenefitId !== undefined) {
                localVarQueryParameter['excludeCustomBenefitId'] = excludeCustomBenefitId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get benefit sorting
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitSorting: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefitSorting', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefitSorting`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary read benefit template by id
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitTemplate: async (tenantId: string, benefitTemplateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefitTemplate', 'tenantId', tenantId)
            // verify required parameter 'benefitTemplateId' is not null or undefined
            assertParamExists('getBenefitTemplate', 'benefitTemplateId', benefitTemplateId)
            const localVarPath = `/tenants/{tenantId}/benefitTemplates/{benefitTemplateId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"benefitTemplateId"}}`, encodeURIComponent(String(benefitTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all benefit templates for tenant
         * @param {string} tenantId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [benefit] 
         * @param {string} [filter] 
         * @param {string} [customBenefitId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitTemplates: async (tenantId: string, benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', filter?: string, customBenefitId?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefitTemplates', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefitTemplates`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (benefit !== undefined) {
                localVarQueryParameter['benefit'] = benefit;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (customBenefitId !== undefined) {
                localVarQueryParameter['customBenefitId'] = customBenefitId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all active benefits for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefits: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBenefits', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getBenefits', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get expected/previous expenses
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpenses: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getExpenses', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/expenses`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generate financial report for all tenant benefits
         * @param {string} tenantId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinancialReport: async (tenantId: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getFinancialReport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/financialReport`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} statementMonth 
         * @param {string} tenantId 
         * @param {string} [filterMonth] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>} [benefits] 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>} [flexBenefitsToInclude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayrollReport: async (statementMonth: string, tenantId: string, filterMonth?: string, start?: string, end?: string, benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>, flexBenefitsToInclude?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statementMonth' is not null or undefined
            assertParamExists('getPayrollReport', 'statementMonth', statementMonth)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPayrollReport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/payrollReport`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filterMonth !== undefined) {
                localVarQueryParameter['filterMonth'] = filterMonth;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (statementMonth !== undefined) {
                localVarQueryParameter['statementMonth'] = statementMonth;
            }

            if (benefits) {
                localVarQueryParameter['benefits'] = benefits;
            }

            if (flexBenefitsToInclude) {
                localVarQueryParameter['flexBenefitsToInclude'] = flexBenefitsToInclude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get upselling campaigns relevant for given tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBenefitUpsellings: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listBenefitUpsellings', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/upsellings`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list benefit activations for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantBenefitActivations: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listTenantBenefitActivations', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefitActivations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ResendPayrollReportRequestDTO} resendPayrollReportRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPayrollReport: async (tenantId: string, resendPayrollReportRequestDTO: ResendPayrollReportRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('sendPayrollReport', 'tenantId', tenantId)
            // verify required parameter 'resendPayrollReportRequestDTO' is not null or undefined
            assertParamExists('sendPayrollReport', 'resendPayrollReportRequestDTO', resendPayrollReportRequestDTO)
            const localVarPath = `/tenants/{tenantId}/benefits/payrollReport/sendPayrollReport`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendPayrollReportRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {UpdateBenefitActivationDTO} updateBenefitActivationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitActivation: async (tenantId: string, employeeId: string, benefitActivationId: string, updateBenefitActivationDTO: UpdateBenefitActivationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateBenefitActivation', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateBenefitActivation', 'employeeId', employeeId)
            // verify required parameter 'benefitActivationId' is not null or undefined
            assertParamExists('updateBenefitActivation', 'benefitActivationId', benefitActivationId)
            // verify required parameter 'updateBenefitActivationDTO' is not null or undefined
            assertParamExists('updateBenefitActivation', 'updateBenefitActivationDTO', updateBenefitActivationDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefitActivations/{benefitActivationId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"benefitActivationId"}}`, encodeURIComponent(String(benefitActivationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBenefitActivationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates benefit activations for the given employees
         * @param {string} tenantId 
         * @param {BulkUpdateBenefitActivationsDTO} bulkUpdateBenefitActivationsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitActivations: async (tenantId: string, bulkUpdateBenefitActivationsDTO: BulkUpdateBenefitActivationsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateBenefitActivations', 'tenantId', tenantId)
            // verify required parameter 'bulkUpdateBenefitActivationsDTO' is not null or undefined
            assertParamExists('updateBenefitActivations', 'bulkUpdateBenefitActivationsDTO', bulkUpdateBenefitActivationsDTO)
            const localVarPath = `/tenants/{tenantId}/benefitActivations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateBenefitActivationsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a benefit template for the tenant
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {UpdateBenefitTemplateDTO} updateBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitTemplate: async (tenantId: string, benefitTemplateId: string, updateBenefitTemplateDTO: UpdateBenefitTemplateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateBenefitTemplate', 'tenantId', tenantId)
            // verify required parameter 'benefitTemplateId' is not null or undefined
            assertParamExists('updateBenefitTemplate', 'benefitTemplateId', benefitTemplateId)
            // verify required parameter 'updateBenefitTemplateDTO' is not null or undefined
            assertParamExists('updateBenefitTemplate', 'updateBenefitTemplateDTO', updateBenefitTemplateDTO)
            const localVarPath = `/tenants/{tenantId}/benefitTemplates/{benefitTemplateId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"benefitTemplateId"}}`, encodeURIComponent(String(benefitTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBenefitTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenefitsApi - functional programming interface
 * @export
 */
export const BenefitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenefitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary apply all benefits of template to employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {ApplyBenefitTemplateDTO} applyBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyBenefitTemplate(tenantId: string, employeeId: string, applyBenefitTemplateDTO: ApplyBenefitTemplateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyBenefitTemplate(tenantId, employeeId, applyBenefitTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary apply all benefits of template to selected employees
         * @param {string} tenantId 
         * @param {BulkApplyBenefitTemplateDTO} bulkApplyBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkApplyBenefitTemplate(tenantId: string, bulkApplyBenefitTemplateDTO: BulkApplyBenefitTemplateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkApplyBenefitTemplate(tenantId, bulkApplyBenefitTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBenefitActivationDTO} createBenefitActivationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBenefitActivation(tenantId: string, employeeId: string, createBenefitActivationDTO: CreateBenefitActivationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBenefitActivation(tenantId, employeeId, createBenefitActivationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates new benefit activations for the given employee
         * @param {string} tenantId 
         * @param {BulkCreateBenefitActivationsDTO} bulkCreateBenefitActivationsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBenefitActivations(tenantId: string, bulkCreateBenefitActivationsDTO: BulkCreateBenefitActivationsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBenefitActivations(tenantId, bulkCreateBenefitActivationsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a new benefit sorting for the tenant
         * @param {string} tenantId 
         * @param {CreateTenantBenefitSortingListDTO} createTenantBenefitSortingListDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBenefitSorting(tenantId: string, createTenantBenefitSortingListDTO: CreateTenantBenefitSortingListDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBenefitSorting(tenantId, createTenantBenefitSortingListDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new benefit template for the tenant
         * @param {string} tenantId 
         * @param {CreateBenefitTemplateDTO} createBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBenefitTemplate(tenantId: string, createBenefitTemplateDTO: CreateBenefitTemplateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBenefitTemplate(tenantId, createBenefitTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenefitActivation(tenantId: string, employeeId: string, benefitActivationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenefitActivation(tenantId, employeeId, benefitActivationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a benefit template for the tenant
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenefitTemplate(tenantId: string, benefitTemplateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenefitTemplate(tenantId, benefitTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary read benefit by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} benefit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefit(tenantId: string, employeeId: string, benefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefit(tenantId, employeeId, benefit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary read benefit activation by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitActivation(tenantId: string, employeeId: string, benefitActivationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitActivationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitActivation(tenantId, employeeId, benefitActivationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all benefit activations from employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [benefit] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [excludeBenefit] 
         * @param {boolean} [onlyActive] 
         * @param {string} [customBenefitId] 
         * @param {string} [excludeCustomBenefitId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitActivations(tenantId: string, employeeId: string, benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', startDate?: string, endDate?: string, excludeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', onlyActive?: boolean, customBenefitId?: string, excludeCustomBenefitId?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitActivationListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitActivations(tenantId, employeeId, benefit, startDate, endDate, excludeBenefit, onlyActive, customBenefitId, excludeCustomBenefitId, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get benefit sorting
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitSorting(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantBenefitSortingListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitSorting(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary read benefit template by id
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitTemplate(tenantId: string, benefitTemplateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitTemplate(tenantId, benefitTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all benefit templates for tenant
         * @param {string} tenantId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [benefit] 
         * @param {string} [filter] 
         * @param {string} [customBenefitId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitTemplates(tenantId: string, benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', filter?: string, customBenefitId?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitTemplateListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitTemplates(tenantId, benefit, filter, customBenefitId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all active benefits for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefits(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefits(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get expected/previous expenses
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpenses(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitExpensesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpenses(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary generate financial report for all tenant benefits
         * @param {string} tenantId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinancialReport(tenantId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialReportDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinancialReport(tenantId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} statementMonth 
         * @param {string} tenantId 
         * @param {string} [filterMonth] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>} [benefits] 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>} [flexBenefitsToInclude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayrollReport(statementMonth: string, tenantId: string, filterMonth?: string, start?: string, end?: string, benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>, flexBenefitsToInclude?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayrollReport(statementMonth, tenantId, filterMonth, start, end, benefits, flexBenefitsToInclude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get upselling campaigns relevant for given tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBenefitUpsellings(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitUpsellingListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBenefitUpsellings(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list benefit activations for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenantBenefitActivations(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenefitActivationListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenantBenefitActivations(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ResendPayrollReportRequestDTO} resendPayrollReportRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPayrollReport(tenantId: string, resendPayrollReportRequestDTO: ResendPayrollReportRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPayrollReport(tenantId, resendPayrollReportRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {UpdateBenefitActivationDTO} updateBenefitActivationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenefitActivation(tenantId: string, employeeId: string, benefitActivationId: string, updateBenefitActivationDTO: UpdateBenefitActivationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenefitActivation(tenantId, employeeId, benefitActivationId, updateBenefitActivationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates benefit activations for the given employees
         * @param {string} tenantId 
         * @param {BulkUpdateBenefitActivationsDTO} bulkUpdateBenefitActivationsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenefitActivations(tenantId: string, bulkUpdateBenefitActivationsDTO: BulkUpdateBenefitActivationsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenefitActivations(tenantId, bulkUpdateBenefitActivationsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a benefit template for the tenant
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {UpdateBenefitTemplateDTO} updateBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenefitTemplate(tenantId: string, benefitTemplateId: string, updateBenefitTemplateDTO: UpdateBenefitTemplateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenefitTemplate(tenantId, benefitTemplateId, updateBenefitTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenefitsApi - factory interface
 * @export
 */
export const BenefitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenefitsApiFp(configuration)
    return {
        /**
         * 
         * @summary apply all benefits of template to employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {ApplyBenefitTemplateDTO} applyBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBenefitTemplate(tenantId: string, employeeId: string, applyBenefitTemplateDTO: ApplyBenefitTemplateDTO, options?: any): AxiosPromise<void> {
            return localVarFp.applyBenefitTemplate(tenantId, employeeId, applyBenefitTemplateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary apply all benefits of template to selected employees
         * @param {string} tenantId 
         * @param {BulkApplyBenefitTemplateDTO} bulkApplyBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkApplyBenefitTemplate(tenantId: string, bulkApplyBenefitTemplateDTO: BulkApplyBenefitTemplateDTO, options?: any): AxiosPromise<BulkCreateResponseDTO> {
            return localVarFp.bulkApplyBenefitTemplate(tenantId, bulkApplyBenefitTemplateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateBenefitActivationDTO} createBenefitActivationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitActivation(tenantId: string, employeeId: string, createBenefitActivationDTO: CreateBenefitActivationDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createBenefitActivation(tenantId, employeeId, createBenefitActivationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates new benefit activations for the given employee
         * @param {string} tenantId 
         * @param {BulkCreateBenefitActivationsDTO} bulkCreateBenefitActivationsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitActivations(tenantId: string, bulkCreateBenefitActivationsDTO: BulkCreateBenefitActivationsDTO, options?: any): AxiosPromise<BulkCreateResponseDTO> {
            return localVarFp.createBenefitActivations(tenantId, bulkCreateBenefitActivationsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a new benefit sorting for the tenant
         * @param {string} tenantId 
         * @param {CreateTenantBenefitSortingListDTO} createTenantBenefitSortingListDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitSorting(tenantId: string, createTenantBenefitSortingListDTO: CreateTenantBenefitSortingListDTO, options?: any): AxiosPromise<void> {
            return localVarFp.createBenefitSorting(tenantId, createTenantBenefitSortingListDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new benefit template for the tenant
         * @param {string} tenantId 
         * @param {CreateBenefitTemplateDTO} createBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBenefitTemplate(tenantId: string, createBenefitTemplateDTO: CreateBenefitTemplateDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createBenefitTemplate(tenantId, createBenefitTemplateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitActivation(tenantId: string, employeeId: string, benefitActivationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBenefitActivation(tenantId, employeeId, benefitActivationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a benefit template for the tenant
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenefitTemplate(tenantId: string, benefitTemplateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBenefitTemplate(tenantId, benefitTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary read benefit by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} benefit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefit(tenantId: string, employeeId: string, benefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', options?: any): AxiosPromise<BenefitDTO> {
            return localVarFp.getBenefit(tenantId, employeeId, benefit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary read benefit activation by id
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitActivation(tenantId: string, employeeId: string, benefitActivationId: string, options?: any): AxiosPromise<BenefitActivationDTO> {
            return localVarFp.getBenefitActivation(tenantId, employeeId, benefitActivationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all benefit activations from employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [benefit] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [excludeBenefit] 
         * @param {boolean} [onlyActive] 
         * @param {string} [customBenefitId] 
         * @param {string} [excludeCustomBenefitId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitActivations(tenantId: string, employeeId: string, benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', startDate?: string, endDate?: string, excludeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', onlyActive?: boolean, customBenefitId?: string, excludeCustomBenefitId?: string, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<BenefitActivationListDTO> {
            return localVarFp.getBenefitActivations(tenantId, employeeId, benefit, startDate, endDate, excludeBenefit, onlyActive, customBenefitId, excludeCustomBenefitId, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get benefit sorting
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitSorting(tenantId: string, options?: any): AxiosPromise<TenantBenefitSortingListDTO> {
            return localVarFp.getBenefitSorting(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary read benefit template by id
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitTemplate(tenantId: string, benefitTemplateId: string, options?: any): AxiosPromise<BenefitTemplateDTO> {
            return localVarFp.getBenefitTemplate(tenantId, benefitTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all benefit templates for tenant
         * @param {string} tenantId 
         * @param {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'} [benefit] 
         * @param {string} [filter] 
         * @param {string} [customBenefitId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitTemplates(tenantId: string, benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM', filter?: string, customBenefitId?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BenefitTemplateListDTO> {
            return localVarFp.getBenefitTemplates(tenantId, benefit, filter, customBenefitId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all active benefits for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefits(tenantId: string, employeeId: string, options?: any): AxiosPromise<BenefitListDTO> {
            return localVarFp.getBenefits(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get expected/previous expenses
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpenses(tenantId: string, options?: any): AxiosPromise<BenefitExpensesDTO> {
            return localVarFp.getExpenses(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generate financial report for all tenant benefits
         * @param {string} tenantId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinancialReport(tenantId: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<FinancialReportDTO> {
            return localVarFp.getFinancialReport(tenantId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} statementMonth 
         * @param {string} tenantId 
         * @param {string} [filterMonth] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>} [benefits] 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>} [flexBenefitsToInclude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayrollReport(statementMonth: string, tenantId: string, filterMonth?: string, start?: string, end?: string, benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>, flexBenefitsToInclude?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>, options?: any): AxiosPromise<string> {
            return localVarFp.getPayrollReport(statementMonth, tenantId, filterMonth, start, end, benefits, flexBenefitsToInclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get upselling campaigns relevant for given tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBenefitUpsellings(tenantId: string, options?: any): AxiosPromise<BenefitUpsellingListDTO> {
            return localVarFp.listBenefitUpsellings(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list benefit activations for tenant
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenantBenefitActivations(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BenefitActivationListDTO> {
            return localVarFp.listTenantBenefitActivations(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {ResendPayrollReportRequestDTO} resendPayrollReportRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPayrollReport(tenantId: string, resendPayrollReportRequestDTO: ResendPayrollReportRequestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendPayrollReport(tenantId, resendPayrollReportRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a benefit activation for the employee
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} benefitActivationId 
         * @param {UpdateBenefitActivationDTO} updateBenefitActivationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitActivation(tenantId: string, employeeId: string, benefitActivationId: string, updateBenefitActivationDTO: UpdateBenefitActivationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenefitActivation(tenantId, employeeId, benefitActivationId, updateBenefitActivationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates benefit activations for the given employees
         * @param {string} tenantId 
         * @param {BulkUpdateBenefitActivationsDTO} bulkUpdateBenefitActivationsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitActivations(tenantId: string, bulkUpdateBenefitActivationsDTO: BulkUpdateBenefitActivationsDTO, options?: any): AxiosPromise<BulkCreateResponseDTO> {
            return localVarFp.updateBenefitActivations(tenantId, bulkUpdateBenefitActivationsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a benefit template for the tenant
         * @param {string} tenantId 
         * @param {string} benefitTemplateId 
         * @param {UpdateBenefitTemplateDTO} updateBenefitTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenefitTemplate(tenantId: string, benefitTemplateId: string, updateBenefitTemplateDTO: UpdateBenefitTemplateDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateBenefitTemplate(tenantId, benefitTemplateId, updateBenefitTemplateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applyBenefitTemplate operation in BenefitsApi.
 * @export
 * @interface BenefitsApiApplyBenefitTemplateRequest
 */
export interface BenefitsApiApplyBenefitTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiApplyBenefitTemplate
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiApplyBenefitTemplate
     */
    readonly employeeId: string

    /**
     * 
     * @type {ApplyBenefitTemplateDTO}
     * @memberof BenefitsApiApplyBenefitTemplate
     */
    readonly applyBenefitTemplateDTO: ApplyBenefitTemplateDTO
}

/**
 * Request parameters for bulkApplyBenefitTemplate operation in BenefitsApi.
 * @export
 * @interface BenefitsApiBulkApplyBenefitTemplateRequest
 */
export interface BenefitsApiBulkApplyBenefitTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiBulkApplyBenefitTemplate
     */
    readonly tenantId: string

    /**
     * 
     * @type {BulkApplyBenefitTemplateDTO}
     * @memberof BenefitsApiBulkApplyBenefitTemplate
     */
    readonly bulkApplyBenefitTemplateDTO: BulkApplyBenefitTemplateDTO
}

/**
 * Request parameters for createBenefitActivation operation in BenefitsApi.
 * @export
 * @interface BenefitsApiCreateBenefitActivationRequest
 */
export interface BenefitsApiCreateBenefitActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiCreateBenefitActivation
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiCreateBenefitActivation
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateBenefitActivationDTO}
     * @memberof BenefitsApiCreateBenefitActivation
     */
    readonly createBenefitActivationDTO: CreateBenefitActivationDTO
}

/**
 * Request parameters for createBenefitActivations operation in BenefitsApi.
 * @export
 * @interface BenefitsApiCreateBenefitActivationsRequest
 */
export interface BenefitsApiCreateBenefitActivationsRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiCreateBenefitActivations
     */
    readonly tenantId: string

    /**
     * 
     * @type {BulkCreateBenefitActivationsDTO}
     * @memberof BenefitsApiCreateBenefitActivations
     */
    readonly bulkCreateBenefitActivationsDTO: BulkCreateBenefitActivationsDTO
}

/**
 * Request parameters for createBenefitSorting operation in BenefitsApi.
 * @export
 * @interface BenefitsApiCreateBenefitSortingRequest
 */
export interface BenefitsApiCreateBenefitSortingRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiCreateBenefitSorting
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateTenantBenefitSortingListDTO}
     * @memberof BenefitsApiCreateBenefitSorting
     */
    readonly createTenantBenefitSortingListDTO: CreateTenantBenefitSortingListDTO
}

/**
 * Request parameters for createBenefitTemplate operation in BenefitsApi.
 * @export
 * @interface BenefitsApiCreateBenefitTemplateRequest
 */
export interface BenefitsApiCreateBenefitTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiCreateBenefitTemplate
     */
    readonly tenantId: string

    /**
     * 
     * @type {CreateBenefitTemplateDTO}
     * @memberof BenefitsApiCreateBenefitTemplate
     */
    readonly createBenefitTemplateDTO: CreateBenefitTemplateDTO
}

/**
 * Request parameters for deleteBenefitActivation operation in BenefitsApi.
 * @export
 * @interface BenefitsApiDeleteBenefitActivationRequest
 */
export interface BenefitsApiDeleteBenefitActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiDeleteBenefitActivation
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiDeleteBenefitActivation
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiDeleteBenefitActivation
     */
    readonly benefitActivationId: string
}

/**
 * Request parameters for deleteBenefitTemplate operation in BenefitsApi.
 * @export
 * @interface BenefitsApiDeleteBenefitTemplateRequest
 */
export interface BenefitsApiDeleteBenefitTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiDeleteBenefitTemplate
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiDeleteBenefitTemplate
     */
    readonly benefitTemplateId: string
}

/**
 * Request parameters for getBenefit operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitRequest
 */
export interface BenefitsApiGetBenefitRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefit
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefit
     */
    readonly employeeId: string

    /**
     * 
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof BenefitsApiGetBenefit
     */
    readonly benefit: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'
}

/**
 * Request parameters for getBenefitActivation operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitActivationRequest
 */
export interface BenefitsApiGetBenefitActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivation
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivation
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivation
     */
    readonly benefitActivationId: string
}

/**
 * Request parameters for getBenefitActivations operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitActivationsRequest
 */
export interface BenefitsApiGetBenefitActivationsRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly employeeId: string

    /**
     * 
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly endDate?: string

    /**
     * 
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly excludeBenefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * 
     * @type {boolean}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly onlyActive?: boolean

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly customBenefitId?: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly excludeCustomBenefitId?: string

    /**
     * 
     * @type {number}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof BenefitsApiGetBenefitActivations
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for getBenefitSorting operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitSortingRequest
 */
export interface BenefitsApiGetBenefitSortingRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitSorting
     */
    readonly tenantId: string
}

/**
 * Request parameters for getBenefitTemplate operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitTemplateRequest
 */
export interface BenefitsApiGetBenefitTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitTemplate
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitTemplate
     */
    readonly benefitTemplateId: string
}

/**
 * Request parameters for getBenefitTemplates operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitTemplatesRequest
 */
export interface BenefitsApiGetBenefitTemplatesRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitTemplates
     */
    readonly tenantId: string

    /**
     * 
     * @type {'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'}
     * @memberof BenefitsApiGetBenefitTemplates
     */
    readonly benefit?: 'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitTemplates
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefitTemplates
     */
    readonly customBenefitId?: string

    /**
     * 
     * @type {number}
     * @memberof BenefitsApiGetBenefitTemplates
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BenefitsApiGetBenefitTemplates
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getBenefits operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetBenefitsRequest
 */
export interface BenefitsApiGetBenefitsRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefits
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetBenefits
     */
    readonly employeeId: string
}

/**
 * Request parameters for getExpenses operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetExpensesRequest
 */
export interface BenefitsApiGetExpensesRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetExpenses
     */
    readonly tenantId: string
}

/**
 * Request parameters for getFinancialReport operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetFinancialReportRequest
 */
export interface BenefitsApiGetFinancialReportRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetFinancialReport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetFinancialReport
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetFinancialReport
     */
    readonly endDate?: string
}

/**
 * Request parameters for getPayrollReport operation in BenefitsApi.
 * @export
 * @interface BenefitsApiGetPayrollReportRequest
 */
export interface BenefitsApiGetPayrollReportRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly statementMonth: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly filterMonth?: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly end?: string

    /**
     * 
     * @type {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>

    /**
     * 
     * @type {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>}
     * @memberof BenefitsApiGetPayrollReport
     */
    readonly flexBenefitsToInclude?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'FITNESS' | 'CUSTOM'>
}

/**
 * Request parameters for listBenefitUpsellings operation in BenefitsApi.
 * @export
 * @interface BenefitsApiListBenefitUpsellingsRequest
 */
export interface BenefitsApiListBenefitUpsellingsRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiListBenefitUpsellings
     */
    readonly tenantId: string
}

/**
 * Request parameters for listTenantBenefitActivations operation in BenefitsApi.
 * @export
 * @interface BenefitsApiListTenantBenefitActivationsRequest
 */
export interface BenefitsApiListTenantBenefitActivationsRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiListTenantBenefitActivations
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof BenefitsApiListTenantBenefitActivations
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof BenefitsApiListTenantBenefitActivations
     */
    readonly pageSize?: number
}

/**
 * Request parameters for sendPayrollReport operation in BenefitsApi.
 * @export
 * @interface BenefitsApiSendPayrollReportRequest
 */
export interface BenefitsApiSendPayrollReportRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiSendPayrollReport
     */
    readonly tenantId: string

    /**
     * 
     * @type {ResendPayrollReportRequestDTO}
     * @memberof BenefitsApiSendPayrollReport
     */
    readonly resendPayrollReportRequestDTO: ResendPayrollReportRequestDTO
}

/**
 * Request parameters for updateBenefitActivation operation in BenefitsApi.
 * @export
 * @interface BenefitsApiUpdateBenefitActivationRequest
 */
export interface BenefitsApiUpdateBenefitActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiUpdateBenefitActivation
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiUpdateBenefitActivation
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiUpdateBenefitActivation
     */
    readonly benefitActivationId: string

    /**
     * 
     * @type {UpdateBenefitActivationDTO}
     * @memberof BenefitsApiUpdateBenefitActivation
     */
    readonly updateBenefitActivationDTO: UpdateBenefitActivationDTO
}

/**
 * Request parameters for updateBenefitActivations operation in BenefitsApi.
 * @export
 * @interface BenefitsApiUpdateBenefitActivationsRequest
 */
export interface BenefitsApiUpdateBenefitActivationsRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiUpdateBenefitActivations
     */
    readonly tenantId: string

    /**
     * 
     * @type {BulkUpdateBenefitActivationsDTO}
     * @memberof BenefitsApiUpdateBenefitActivations
     */
    readonly bulkUpdateBenefitActivationsDTO: BulkUpdateBenefitActivationsDTO
}

/**
 * Request parameters for updateBenefitTemplate operation in BenefitsApi.
 * @export
 * @interface BenefitsApiUpdateBenefitTemplateRequest
 */
export interface BenefitsApiUpdateBenefitTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof BenefitsApiUpdateBenefitTemplate
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof BenefitsApiUpdateBenefitTemplate
     */
    readonly benefitTemplateId: string

    /**
     * 
     * @type {UpdateBenefitTemplateDTO}
     * @memberof BenefitsApiUpdateBenefitTemplate
     */
    readonly updateBenefitTemplateDTO: UpdateBenefitTemplateDTO
}

/**
 * BenefitsApi - object-oriented interface
 * @export
 * @class BenefitsApi
 * @extends {BaseAPI}
 */
export class BenefitsApi extends BaseAPI {
    /**
     * 
     * @summary apply all benefits of template to employee
     * @param {BenefitsApiApplyBenefitTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public applyBenefitTemplate(requestParameters: BenefitsApiApplyBenefitTemplateRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).applyBenefitTemplate(requestParameters.tenantId, requestParameters.employeeId, requestParameters.applyBenefitTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary apply all benefits of template to selected employees
     * @param {BenefitsApiBulkApplyBenefitTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public bulkApplyBenefitTemplate(requestParameters: BenefitsApiBulkApplyBenefitTemplateRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).bulkApplyBenefitTemplate(requestParameters.tenantId, requestParameters.bulkApplyBenefitTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new benefit activation for the employee
     * @param {BenefitsApiCreateBenefitActivationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public createBenefitActivation(requestParameters: BenefitsApiCreateBenefitActivationRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).createBenefitActivation(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createBenefitActivationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates new benefit activations for the given employee
     * @param {BenefitsApiCreateBenefitActivationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public createBenefitActivations(requestParameters: BenefitsApiCreateBenefitActivationsRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).createBenefitActivations(requestParameters.tenantId, requestParameters.bulkCreateBenefitActivationsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a new benefit sorting for the tenant
     * @param {BenefitsApiCreateBenefitSortingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public createBenefitSorting(requestParameters: BenefitsApiCreateBenefitSortingRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).createBenefitSorting(requestParameters.tenantId, requestParameters.createTenantBenefitSortingListDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new benefit template for the tenant
     * @param {BenefitsApiCreateBenefitTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public createBenefitTemplate(requestParameters: BenefitsApiCreateBenefitTemplateRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).createBenefitTemplate(requestParameters.tenantId, requestParameters.createBenefitTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a benefit activation for the employee
     * @param {BenefitsApiDeleteBenefitActivationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public deleteBenefitActivation(requestParameters: BenefitsApiDeleteBenefitActivationRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).deleteBenefitActivation(requestParameters.tenantId, requestParameters.employeeId, requestParameters.benefitActivationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a benefit template for the tenant
     * @param {BenefitsApiDeleteBenefitTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public deleteBenefitTemplate(requestParameters: BenefitsApiDeleteBenefitTemplateRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).deleteBenefitTemplate(requestParameters.tenantId, requestParameters.benefitTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary read benefit by id
     * @param {BenefitsApiGetBenefitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefit(requestParameters: BenefitsApiGetBenefitRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefit(requestParameters.tenantId, requestParameters.employeeId, requestParameters.benefit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary read benefit activation by id
     * @param {BenefitsApiGetBenefitActivationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefitActivation(requestParameters: BenefitsApiGetBenefitActivationRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefitActivation(requestParameters.tenantId, requestParameters.employeeId, requestParameters.benefitActivationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all benefit activations from employee
     * @param {BenefitsApiGetBenefitActivationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefitActivations(requestParameters: BenefitsApiGetBenefitActivationsRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefitActivations(requestParameters.tenantId, requestParameters.employeeId, requestParameters.benefit, requestParameters.startDate, requestParameters.endDate, requestParameters.excludeBenefit, requestParameters.onlyActive, requestParameters.customBenefitId, requestParameters.excludeCustomBenefitId, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get benefit sorting
     * @param {BenefitsApiGetBenefitSortingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefitSorting(requestParameters: BenefitsApiGetBenefitSortingRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefitSorting(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary read benefit template by id
     * @param {BenefitsApiGetBenefitTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefitTemplate(requestParameters: BenefitsApiGetBenefitTemplateRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefitTemplate(requestParameters.tenantId, requestParameters.benefitTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all benefit templates for tenant
     * @param {BenefitsApiGetBenefitTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefitTemplates(requestParameters: BenefitsApiGetBenefitTemplatesRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefitTemplates(requestParameters.tenantId, requestParameters.benefit, requestParameters.filter, requestParameters.customBenefitId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all active benefits for the employee
     * @param {BenefitsApiGetBenefitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getBenefits(requestParameters: BenefitsApiGetBenefitsRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getBenefits(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get expected/previous expenses
     * @param {BenefitsApiGetExpensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getExpenses(requestParameters: BenefitsApiGetExpensesRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getExpenses(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generate financial report for all tenant benefits
     * @param {BenefitsApiGetFinancialReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getFinancialReport(requestParameters: BenefitsApiGetFinancialReportRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getFinancialReport(requestParameters.tenantId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BenefitsApiGetPayrollReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public getPayrollReport(requestParameters: BenefitsApiGetPayrollReportRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).getPayrollReport(requestParameters.statementMonth, requestParameters.tenantId, requestParameters.filterMonth, requestParameters.start, requestParameters.end, requestParameters.benefits, requestParameters.flexBenefitsToInclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get upselling campaigns relevant for given tenant
     * @param {BenefitsApiListBenefitUpsellingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public listBenefitUpsellings(requestParameters: BenefitsApiListBenefitUpsellingsRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).listBenefitUpsellings(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list benefit activations for tenant
     * @param {BenefitsApiListTenantBenefitActivationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public listTenantBenefitActivations(requestParameters: BenefitsApiListTenantBenefitActivationsRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).listTenantBenefitActivations(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BenefitsApiSendPayrollReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public sendPayrollReport(requestParameters: BenefitsApiSendPayrollReportRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).sendPayrollReport(requestParameters.tenantId, requestParameters.resendPayrollReportRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a benefit activation for the employee
     * @param {BenefitsApiUpdateBenefitActivationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public updateBenefitActivation(requestParameters: BenefitsApiUpdateBenefitActivationRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).updateBenefitActivation(requestParameters.tenantId, requestParameters.employeeId, requestParameters.benefitActivationId, requestParameters.updateBenefitActivationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates benefit activations for the given employees
     * @param {BenefitsApiUpdateBenefitActivationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public updateBenefitActivations(requestParameters: BenefitsApiUpdateBenefitActivationsRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).updateBenefitActivations(requestParameters.tenantId, requestParameters.bulkUpdateBenefitActivationsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a benefit template for the tenant
     * @param {BenefitsApiUpdateBenefitTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenefitsApi
     */
    public updateBenefitTemplate(requestParameters: BenefitsApiUpdateBenefitTemplateRequest, options?: AxiosRequestConfig) {
        return BenefitsApiFp(this.configuration).updateBenefitTemplate(requestParameters.tenantId, requestParameters.benefitTemplateId, requestParameters.updateBenefitTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
