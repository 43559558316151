import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { BENEFIT_PICTURES } from 'probonio-shared-ui/component/button';
import { Trans, useTranslation } from 'react-i18next';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
interface TextProps {
  title: string;
  children: React.ReactNode;
}
const RecreationInfo: React.FC<TextProps> = ({ title, children }) => {
  return (
    <Paper elevation={0} sx={{ paddingTop: 0.3, width: '100%' }}>
      <Box sx={{ ml: 0.8 }}>
        <Typography variant="descriptionBold">{title}</Typography>
        <Divider variant={'fullWidth'} sx={{ marginRight: 0.3, marginBottom: 0.3, marginTop: 0.1 }} />
        <Box marginLeft={0.3} marginRight={0.5} marginBottom={0.3} display="flex" alignItems={'center'} gap={0.5}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};
const RecreationInfoPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  return (
    <MobileLayout header={<AppToolbar absolute invert backButton={<BackIconButton onClick={backNavigator.back} color="inherit" />} />}>
      <Box>
        <img height={250} src={BENEFIT_PICTURES.RECREATION} style={{ width: '100%', objectFit: 'cover' }} />
      </Box>
      <Stack spacing={0.5}>
        <RecreationInfo title={t('benefit.RECREATION.infoPage.intro.title')}>
          <Trans i18nKey="benefitModule:benefit.RECREATION.infoPage.intro.description" />
        </RecreationInfo>
        <RecreationInfo title={t('benefit.RECREATION.infoPage.guide.title')}>
          <Trans i18nKey="benefitModule:benefit.RECREATION.infoPage.guide.description" />
        </RecreationInfo>
        <RecreationInfo title={t('benefit.RECREATION.infoPage.rules.title')}>
          <Trans i18nKey="benefitModule:benefit.RECREATION.infoPage.rules.description" />
        </RecreationInfo>
        <RecreationInfo title={t('benefit.RECREATION.infoPage.preconditions.title')}>
          <Trans i18nKey="benefitModule:benefit.RECREATION.infoPage.preconditions.description" />
        </RecreationInfo>
        <Box display="flex" justifyContent="center" mb={0.5}>
          <TenantLogo sx={{ width: '80%', textAlign: 'center' }} />
        </Box>
      </Stack>
    </MobileLayout>
  );
};
export default RecreationInfoPage;
