import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  loading?: boolean;
  loadingVariant?: 'determinate' | 'indeterminate';
  loadingValue?: number;
  dataTestId?: string;
}

export const LoadingButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ loading, dataTestId, disabled, loadingVariant = 'indeterminate', loadingValue, children, ...props }, ref) => {
    const ProgressIcon: React.FC<{ loadingVariant: 'determinate' | 'indeterminate' }> = ({ loadingVariant }) => {
      const { t } = useTranslation('common');
      switch (loadingVariant) {
        case 'determinate': {
          return (
            <Box style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              {t('completed', { progress: Math.round(loadingValue || 0) })}
              <CircularProgress variant={'determinate'} value={loadingValue || 0} color={'primary'} size={28} />
            </Box>
          );
        }
        case 'indeterminate': {
          return (
            <CircularProgress
              color={'inherit'}
              size={28}
              sx={{
                position: 'absolute',
                marginLeft: 'auto',
                marginRight: 'auto',
                left: 0,
                right: 0,
              }}
            />
          );
        }
      }
    };

    return (
      <Button
        ref={ref}
        disabled={loading || disabled}
        {...props}
        sx={{ '& .MuiButton-startIcon': { opacity: loading ? 0 : 1, transition: 'opacity 0.1s' }, ...props.sx }}
      >
        {loading && <ProgressIcon loadingVariant={loadingVariant} />}

        {loading && loadingVariant === 'determinate' ? (
          <></>
        ) : (
          <span data-test-id={dataTestId} style={{ display: 'inline-flex', opacity: loading ? 0 : 1, transition: 'opacity 0.1s' }}>
            {children}
          </span>
        )}
      </Button>
    );
  },
);

export default LoadingButton;
