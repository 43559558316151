import { Badge, Box, Button, styled, SvgIconProps, Theme, Typography, useTheme } from '@mui/material';
import { ComponentType } from 'react';
import { CircularProgressBar } from '../progress';
import { Trans } from 'react-i18next';
import { SxProps } from '@mui/material';

const BenefitButtonStyled = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '.content': {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    gap: `0 ${theme.spacing(0.5)}`,
    width: '100%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  '.description-container': {
    textAlign: 'right',
  },
  '.benefit-button-icon': {
    position: 'absolute',
    width: 32,
    height: 32,
  },
  '.benefit-button-title': {
    // Hard coded white, due to the text looking bad in Dark mode otherwise
    color: 'white',
    margin: 0,
  },
}));

interface BenefitButtonProps {
  sx?: SxProps<Theme>;
  icon: ComponentType<SvgIconProps<'svg', {}>>;
  title: string;
  picture?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  benefitProgress: number;

  descriptionKey?: string;
  descriptionValues?: object;
  badgeValue?: number;
}

export const BenefitButton: React.FC<BenefitButtonProps> = ({
  sx,
  icon,
  title,
  onClick,
  picture,
  benefitProgress,
  descriptionKey,
  descriptionValues,
  badgeValue,
}) => {
  const theme = useTheme();

  const Icon = icon;
  return (
    <BenefitButtonStyled
      sx={{
        ...(sx || {}),
        // Just as fallback if no picture is set.
        backgroundColor: theme.palette.primary.main,
        ':hover': {
          backgroundColor: theme.palette.primary.main,
        },
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent), url(${picture});`,
      }}
      onClick={onClick}
      data-test-id={title}
    >
      <div className="content">
        <Box className="benefit-button-progress" color={theme.palette.primary.light}>
          <Badge badgeContent={badgeValue} color="error" overlap="circular">
            <CircularProgressBar
              size="64px"
              backgroundColor={theme.palette.common.white}
              color={'inherit'}
              variant="determinate"
              thickness={4}
              value={benefitProgress}
              className="circle"
            >
              <Icon className="benefit-button-icon" />
            </CircularProgressBar>
          </Badge>
        </Box>
        <div className="description-container">
          <Typography
            variant="subtitle1"
            className="benefit-button-title"
            lineHeight={1.6}
            textOverflow="ellipsis"
            overflow="hidden"
            sx={{ wordBreak: 'break-word', hyphens: 'auto' }}
          >
            {title}
          </Typography>
          {descriptionKey && descriptionValues ? (
            // Hard coded white, due to the text looking bad in Dark mode otherwise
            <Typography className="benefit-button-subtitle" variant="body2" color="white">
              <Trans i18nKey={descriptionKey} values={descriptionValues}>
                <Typography component="span" variant="inherit" fontWeight={700} color="primary.light" />
                <Typography component="span" variant="inherit" fontWeight={700} />
              </Trans>
            </Typography>
          ) : null}
        </div>
      </div>
    </BenefitButtonStyled>
  );
};
